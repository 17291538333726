import { IEnvironment } from './ienvironment';
import { Injectable } from '@angular/core';
import { environment } from './environment';

@Injectable({
	providedIn: 'root'
})
export class EnvironmentService implements IEnvironment {
	get enableDebugTools() {
		return environment.enableDebugTools;
	}
	get appName() {
		return environment.appName;
	}
	get versions() {
		return environment.versions;
	}
	get envName() {
		return environment.envName;
	}
	get production() {
		return environment.production;
	}
	get test() {
		return environment.test;
	}
	get i18nPrefix() {
		return environment.i18nPrefix;
	}
	get logLevel() {
		return environment.logLevel;
	}
	get clientDomain() {
		return environment.clientDomain;
	}
	get backendDomain() {
		return environment.backendDomain;
	}
	get apiDomain() {
		return environment.apiDomain;
	}
	get authDomain() {
		return environment.authDomain;
	}

	get loginUrl() {
		return environment.loginUrl;
	}

	get softLoginUrl() {
		return environment.softLoginUrl;
	}

	get renewTokenUrl() {
		return environment.renewTokenUrl;
	}
	get logoutUrl() {
		return environment.logoutUrl;
	}

	get contentPath() {
		return environment.contentPath;
	}

	get minimumLocalFreeSpace() {
		return environment.minimumLocalFreeSpace;
	}

	get dottnetMinisitoWidth() {
		return environment.dottnetMinisitoWidth;
	}

	get zoomMeetingNumber() {
		return environment.zoomMeetingNumber;
	}

	get zoomSdkKey() {
		return environment.zoomSdkKey;
	}

	get zoomPasscode() {
		return environment.zoomPasscode;
	}

	get stripePkKey() {
		return environment.stripePkKey;
	}

	get adUnitCompanion() {
		return environment.adUnitCompanion;
	}

	get adUnitBillboard() {
		return environment.adUnitBillboard;
	}

	get adUnitSquare() {
		return environment.adUnitSquare;
	}

  get adUnitLivrea() {
		return environment.adUnitLivrea;
	}

	get divCompanion() {
		return environment.divCompanion;
	}
	get divCompanionMobile() {
		return environment.divCompanionMobile;
	}

	get divBillboard() {
		return environment.divBillboard;
	}

	get divSquare() {
		return environment.divSquare;
	}

  get divLivrea() {
		return environment.divLivrea;
	}

	get adTagUrlCompanion() {
		return environment.adTagUrlCompanion;
	}
	get courseUrl() {
		return environment.courseUrl;
	}

	get surveyUrl() {
		return environment.surveyUrl;
	}
	get landingPageUrl() {
		return environment.landingPageUrl;
	}
	get formUrl() {
		return environment.formUrl;
	}
	get privacyPolicyUrl() {
		return environment.privacyPolicyUrl;
	}
	get imprintUrl() {
		return environment.imprintUrl;
	}
	get cookies() {
		return environment.cookies;
	}
	constructor() {}
}
