import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { selectAuthLoginState } from '../../core/auth/auth.selectors';
import { LogService } from '../../core/log/log.service';
import { onlyWhenOnline, onlyWhenAuthenticated } from '../../shared/util/operators';
import { ErrorMessage } from '../error/error-message.model';
import { manageError } from '../error/error.util';
import { loadProvince, loadProvinceFailure, loadProvinceSuccess } from './province.actions';
import { Province } from './province.model';
import { selectProvince } from './province.selectors';
import { ProvinceService } from './province.service';

@Injectable({ providedIn: 'root' })
export class ProvinceEffects {
	loadProvince$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadProvince),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap((action) => this.logService.info('Effect: loadProvince', action)),
			concatLatestFrom(() =>
				this.store.select(selectProvince).pipe(filter((provinces) => provinces.length === 0))
			),
			switchMap(() =>
				this.provinceService.loadProvince().pipe(
					tap((data: Province[]) =>
						this.logService.infoDebug('Effect: loadProvince data received --> ', data)
					),
					map((datiProvince: Province[]) => loadProvinceSuccess({ datiProvince })),
					catchError((error: ErrorMessage) => of(loadProvinceFailure({ error })))
				)
			)
		)
	);

	loadProvinceFailure$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadProvinceFailure),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			tap((action) => this.logService.info('Effect: loadProvinceFailure ', action)),
			map(([{ error }, authLoginState]) => manageError(error, authLoginState))
		)
	);

	constructor(
		private actions$: Actions,
		private provinceService: ProvinceService,
		private logService: LogService,
		private store: Store
	) {}
}
