import { Action, createReducer, on } from '@ngrx/store';
import { ErrorMessage } from '../../domain/error/error-message.model';
import { createHardLoginSessionFailure, createHardLoginSessionSuccess, createSessionFailure, createSessionSuccess, resetSession } from './session.actions';
import { SessionState } from './session.models';

export const initialState: SessionState = {
	sessionID: 0,
	errorMessage: <ErrorMessage>{}
};

const reducer = createReducer(
	initialState,
	on(createSessionSuccess, (state, { sessionID: value }) => ({
		...state,
		sessionID: value
	})),

	on(createSessionFailure, (state, { error }) => ({ ...state, error: error })),

  on(createHardLoginSessionSuccess, (state, { sessionID: value }) => ({
		...state,
		sessionID: value
	})),

	on(createHardLoginSessionFailure, (state, { error }) => ({ ...state, error: error })),


	on(resetSession, () => ({
		...initialState
	}))
);

export function sessionReducer(state: SessionState | undefined, action: Action): SessionState {
	return reducer(state, action);
}
