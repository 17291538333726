import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorMessage } from '../error/error-message.model';
import { Category } from './category.model';

export const categoryAdapter: EntityAdapter<Category> = createEntityAdapter<Category>({
	selectId: (category) => category.idCategoria
});
export interface CategoryState extends EntityState<Category> {
	error: ErrorMessage;
}
export const categoryInitialState: CategoryState = categoryAdapter.getInitialState({
	error: undefined
});
export const categorySelectors = categoryAdapter.getSelectors();
