import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LogEntry } from '../log/log.model';
import { CallService } from '../../core/call-service/browser/call.service';
import { LogService } from '../../core/log/log.service';
import {
	getCommentsByContentIdUrl,
	getContentByIdUrl,
	getContentListByCategorytUrl,
	getContentListUrl,
	getContentsCorrelatiByCategorieUrl,
	getContentSearchListUrl,
	getContentsLatestNewsUrl,
	getContentsMostReadUrl,
	getContentsRelatedUrl,
	getHighlightByIdRubricaEvidenzaUrl,
	getHighlightByIdRubricaUrl,
	getInsertCommentUrl,
	getInsertMediaAndCommentUrl,
	getLatestVideosUrl,
	getLoadChildrenFromCollectionUrl,
	getSaveLogsUrl,
	getUploadTemporaryMediaUrl
} from '../../core/router/routes-provider';
import { Content, DNComment } from './content.model';

@Injectable({
	providedIn: 'root'
})
export class ContentService {
	constructor(private callService: CallService, private logService: LogService) {}

	loadContentList(
		template: string,
		userId: number,
		fromWhere: number,
		howMany: number,
		premium: boolean
	) {
		const urltoCall = getContentListUrl(template, userId, fromWhere, howMany, premium);
		this.logService.info('Calling ', urltoCall);
		const result = this.callService.CallApi<Content[]>('GET', urltoCall);
		return result;
	}

	loadContentListByCategory(
		categoryId: number,
		userId: number,
		fromWhere: number,
		howMany: number,
		premium: boolean
	) {
		const urltoCall = getContentListByCategorytUrl(categoryId, userId, fromWhere, howMany, premium);
		this.logService.info('Calling ', urltoCall);
		const result = this.callService.CallApi<Content[]>('GET', urltoCall);
		return result;
	}

	loadContentSearchList(userId: number, searchKey: string, fromWhere: number, howMany: number) {
		const urltoCall = getContentSearchListUrl(userId, searchKey, fromWhere, howMany);
		this.logService.info('Calling ', urltoCall);
		const result = this.callService.CallApi<Content[]>('GET', urltoCall);
		return result;
	}

	loadContentById(userId: number, contentId: number, template: string): Observable<Content> {
		const urlToCall = getContentByIdUrl(contentId, userId, template);
		this.logService.info('Calling ', urlToCall);
		return this.callService.CallApi<Content>('GET', urlToCall);
	}

	loadChildrenFromCollection(
		collectionId: number,
		detail: number,
		userId: number
	): Observable<Content[]> {
		const urlToCall = getLoadChildrenFromCollectionUrl(collectionId, detail, userId);
		this.logService.info('Calling ', urlToCall);
		return this.callService.CallApi<Content[]>('GET', urlToCall);
	}

	loadHighlightByIdRubricaEvidenza(
		idRubrica: number,
		isDetail: number,
		userId: number,
		template: string,
		idCategory: number
	): Observable<Content> {
		const urlToCall = getHighlightByIdRubricaEvidenzaUrl(
			idRubrica,
			isDetail,
			userId,
			template,
			idCategory
		);
		this.logService.info('Calling ', urlToCall);
		return this.callService.CallApi<Content>('GET', urlToCall);
	}

	loadHighlightByIdRubrica(
		idRubrica: number,
		isDetail: number,
		userId: number,
		template: string,
		fromWhere: number,
		howMany: number
	): Observable<Content[]> {
		const urlToCall = getHighlightByIdRubricaUrl(
			idRubrica,
			isDetail,
			userId,
			template,
			fromWhere,
			howMany
		);
		this.logService.info('Calling ', urlToCall);
		return this.callService.CallApi<Content[]>('GET', urlToCall);
	}

	//  Related

	loadContentRelated(
		category: number,
		template: string,
		contentId: number,
		contextId: number,
		userId: number,
		fromWhere: number,
		howMany: number
	): Observable<Content[]> {
		const urlToCall = getContentsRelatedUrl(
			category,
			template,
			contentId,
			contextId,
			userId,
			fromWhere,
			howMany
		);
		this.logService.info('Calling ', urlToCall);
		const result = this.callService.CallApi<Content[]>('GET', urlToCall);
		return result;
	}

	//  Latest Videos

	loadContentLatestVideos(
		userId: number,
		fromWhere: number,
		howMany: number
	): Observable<Content[]> {
		const urlToCall = getLatestVideosUrl(userId, fromWhere, howMany);
		this.logService.info('Calling ', urlToCall);
		const result = this.callService.CallApi<Content[]>('GET', urlToCall);
		return result;
	}

	//  Most read

	loadContentMostRead(userId: number, fromWhere: number, howMany: number): Observable<Content[]> {
		const urlToCall = getContentsMostReadUrl(userId, fromWhere, howMany);
		this.logService.info('Calling ', urlToCall);
		const result = this.callService.CallApi<Content[]>('GET', urlToCall);
		return result;
	}

	//  Latest News

	loadContentLatestNews(userId: number, fromWhere: number, howMany: number): Observable<Content[]> {
		const urlToCall = getContentsLatestNewsUrl(userId, fromWhere, howMany);
		this.logService.info('Calling ', urlToCall);
		const result = this.callService.CallApi<Content[]>('GET', urlToCall);
		return result;
	}

	//  Interesting

	loadContentsCorrelatiByCategorieUrl(
		template: string,
		listaIdCategorie: string,
		userId: number,
		contentId: number,
		fromWhere: number,
		howMany: number
	): Observable<Content[]> {
		const urlToCall = getContentsCorrelatiByCategorieUrl(
			template,
			listaIdCategorie,
			userId,
			contentId,
			fromWhere,
			howMany
		);
		this.logService.info('Calling ', urlToCall);
		const result = this.callService.CallApi<Content[]>('GET', urlToCall);
		return result;
	}

	// Comment

	loadCommentsByContentId(
		userId: number,
		commentId: number,
		template: string
	): Observable<DNComment[]> {
		const urlToCall = getCommentsByContentIdUrl(commentId, userId, template);
		this.logService.info('Calling ', urlToCall);
		return this.callService.CallApi<DNComment[]>('GET', urlToCall);
	}

	insertComment(
		parentId: number,
		parentTemplate: string,
		title: string,
		text: string,
		authorId: number,
		attachmentId: number,
		attachmentTypeId: number,
		template: string,
		clientSessionId: number
	) {
		const urlToCall = getInsertCommentUrl(
			parentId,
			parentTemplate,
			title,
			text,
			authorId,
			attachmentId,
			attachmentTypeId,
			template,
			clientSessionId
		);
		this.logService.info('Calling ', urlToCall);
		return this.callService.CallApi<DNComment[]>('POST', urlToCall);
	}

	uploadTemporaryMedia(userId: number, media: File) {
		const urlToCall = getUploadTemporaryMediaUrl(userId);
		this.logService.info('Calling ', urlToCall);

		const formData: FormData = new FormData();
		formData.append('uploadedContent', media, media.name);
		return this.callService.CallApi<string>('POST', urlToCall, formData);
	}

	insertMediaAndComment(
		parentId: number,
		parentTemplate: string,
		title: string,
		text: string,
		commentTemplate: string,
		clientSessionId: number,
		userId: number,
		templateId: number,
		contentTypeId: number,
		fileName: string,
		templateDescription: string
	) {
		const urlToCall = getInsertMediaAndCommentUrl(
			parentId,
			parentTemplate,
			title,
			text,
			commentTemplate,
			clientSessionId,
			userId,
			templateId,
			contentTypeId,
			fileName,
			templateDescription
		);
		this.logService.info('Calling ', urlToCall);

		return this.callService.CallApi<DNComment[]>('POST', urlToCall);
	}

	// this should stai in LogDbService, but there it creates a circular dependency
	saveLogsToBackend(logs: LogEntry[], sessionGuid: string) {
		const urlToCall = getSaveLogsUrl(sessionGuid);

		const result = this.callService.CallApi('POST', urlToCall, JSON.stringify(logs));

		return result;
	}
}
