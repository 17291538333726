/* eslint-disable no-console */
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../../../environments/environment.service';
import { ErrorMessage } from '../../domain/error/error-message.model';
import { LogEntry, LogLevel, logLevelToString } from '../../domain/log/log.model';
import { LogDbService } from '../local-db/logdb.service';
import { NotificationService } from '../notifications/notification.service';

@Injectable({
	providedIn: 'root'
})
export class LogService {
	logLevel: LogLevel = LogLevel.INFO;
	streamOpen: boolean = false;
	toConsole: boolean = true;
	log: boolean = true;
	view: boolean = false;
	_idAnagrafica: number;
	started: boolean = false;

	unableToSave: boolean = false;

	constructor(
		private notificationService: NotificationService,
		private environmentService: EnvironmentService,
		private logDbService: LogDbService,
		@Inject(PLATFORM_ID) private platformId: Object
	) { }

	init(
		toConsole: boolean = true,
		log: boolean = true,
		view: boolean = false,
		logLevel: LogLevel,
		idAnagrafica?: number
	) {
		this.toConsole = toConsole;
		this.log = log;
		this.view = view;
		this.logLevel = logLevel;
		this.idAnagrafica = !!idAnagrafica ? idAnagrafica : -1;
		this.started = true;
		this.testLocalStorageSpace();
	}

	set idAnagrafica(value: number) {
		if (value != null) {
			this._idAnagrafica = value;
		}
	}

	get idAnagrafica(): number {
		return this._idAnagrafica;
	}

	is(logLevel: LogLevel): boolean {
		return this.logLevel >= logLevel;
	}

	testLocalStorageSpace(): void {
		if (isPlatformBrowser(this.platformId)) {
			if ('storage' in navigator && 'estimate' in navigator.storage) {
				navigator.storage
					.estimate()
					.then((value: StorageEstimate) => {
						if ('quota' in value && 'usage' in value) {
							//   if remaining space is less than minimum needed purge db
							if (value.quota - value.usage < this.environmentService.minimumLocalFreeSpace) {
								//  delete from indexed-db to make room for  new logs
								const viewLogs: Date = new Date();
								viewLogs.setDate(viewLogs.getDate() - 7);
								// const deleted = this.logDbService.DeleteLogByDate(viewLogs);
								// const recordDeleted: string = this.translateService.instant('dottnet.logs.delete', {
								// 	value: deleted
								// });
								// this.info(recordDeleted);
							}
						}
					})
					.catch((err) => console.log(err));
			}
		}
	}

	deleteAll() {
		this.logDbService.deleteAllLogsBeforeToday();
	}

/*	getLogsByDate(fromDate: Date): Observable<LogEntry[]> {
		if (isPlatformBrowser(this.platformId)) return this.logDbService.getLogsByDate(fromDate);
	}
*/
/*	getLogsByLogLevel(logLevel: LogLevel): Observable<LogEntry[]> {
		if (isPlatformBrowser(this.platformId))
			return this.logDbService.getLogsByLogLevel(logLevelToString(logLevel));
	}
*/
	logga(
		rowType: string,
		writeFunction: (message?: any, ...optionalParams: any[]) => void,
		message: string,
		furtherData?: any,
		errorCode?: number,
		view?: boolean
	) {
		if (this.log && !this.unableToSave) {
			//  log in sql storage
			/*
			{ name: 'user', keypath: 'user', options: { unique: false } },
			{ name: 'row_type', keypath: 'row_type', options: { unique: false } },
			{ name: 'messagedate', keypath: 'messagedate', options: { unique: false } },
			{ name: 'code', keypath: 'code', options: { unique: false } },
			{ name: 'message', keypath: 'message', options: { unique: false } },
			{ name: 'furtherdata', keypath: 'furtherdata', options: { unique: false } },
		  ] 
	   */ //  const logEntry: LogEntry = {

			let escapedFurtherData: string;
			let jsonFurtherData: string = '';
			if (furtherData) {
				try {
					jsonFurtherData = JSON.stringify(furtherData);
				}
				catch (e: any) {
					//manage Converting circular structure to Json with an ad hoc replacer
					jsonFurtherData = JSON.stringify(furtherData, getCircularReplacer);
				}
				escapedFurtherData = jsonFurtherData ? this.escapeJson(jsonFurtherData) : '';
			}

			const log = new LogEntry(
				this.idAnagrafica ? this.idAnagrafica : -1,
				rowType,
				Date.now(),
				!!message ? message : '',
				errorCode !== undefined ? errorCode : 0,
				!!escapedFurtherData ? escapedFurtherData : ''
			);

			if (isPlatformBrowser(this.platformId)) {
				// eslint-disable-next-line no-console
				this.logDbService.save(log).catch((error) => (this.unableToSave = true));
			}
		}
		if (this.toConsole) {
			// write(rowType + ': ' + message, furtherData ? furtherData : undefined);
			if (furtherData) {
				writeFunction(rowType + ': ' + message, furtherData ? furtherData : undefined);
			} else {
				writeFunction(rowType + ': ' + message);
			}

			view = view == undefined ? this.view : view;
			if (view) {
				let completeMessage = rowType + ': ' + message;
				if (furtherData !== undefined) {
					completeMessage += JSON.stringify(furtherData);
				}
				this.notificationService.showLog(completeMessage, this.logLevel);
			}
		}
	}

	infoDebug(message: string, smallFurtherData?: any, bigFurtherData?: any) {
		if (!this.started) {
			return;
		}

		if (this.logLevel >= LogLevel.DEBUG) {
			this.logga(logLevelToString(LogLevel.DEBUG), console.debug, message, bigFurtherData);
		} else {
			this.logga(logLevelToString(LogLevel.INFO), console.info, message, smallFurtherData);
		}
	}

	infoTrace(message: string, smallFurtherData?: any, bigFurtherData?: any) {
		if (!this.started) {
			return;
		}

		if (this.logLevel >= LogLevel.TRACE) {
			this.logga(logLevelToString(LogLevel.TRACE), console.debug, message, bigFurtherData);
		} else {
			this.logga(logLevelToString(LogLevel.INFO), console.info, message, smallFurtherData);
		}
	}

	trace(message: string, furtherData?: any) {
		if (!this.started) {
			return;
		}

		if (this.logLevel >= LogLevel.TRACE) {
			this.logga('TRACE', console.trace, message, furtherData);
		}
	}
	debug(message: string, furtherData?: any) {
		if (!this.started) {
			return;
		}

		if (this.logLevel >= LogLevel.DEBUG) {
			this.logga('DEBUG', console.debug, message, furtherData);
		}
	}

	info(message: string, furtherData?: any) {
		if (!this.started) {
			return;
		}

		if (this.logLevel >= LogLevel.INFO) {
			this.logga('INFO', console.info, message, furtherData);
		}
	}

	warn(message: string, furtherData?: any) {
		if (!this.started) {
			return;
		}

		if (this.logLevel >= LogLevel.WARN) {
			this.logga('WARN', console.warn, message, furtherData);
		}
	}

	error(message: string, furtherData?: any) {
		if (!this.started) {
			return;
		}

		this.logga('ERROR', console.error, message, furtherData, undefined, false);
	}

	typedError(message: string, errorMessage: ErrorMessage) {
		if (!this.started) {
			return;
		}

		const completeMessage = JSON.stringify(errorMessage);
		this.logga('ERROR', console.error, message, completeMessage, errorMessage.Code);
	}

	escapeJson(json: string) {
		const lunghezza = json.length;
		let newString = '';
		let alreadyEscaping = false;
		for (let i = 0; i < lunghezza; i++) {
			switch (json[i]) {
				case '\\':
					if (alreadyEscaping) {
						newString += '\\';
						alreadyEscaping = false;
					} else {
						alreadyEscaping = true;
					}
					break;
				// eslint-disable-next-line @typescript-eslint/quotes
				case "'":
					if (!alreadyEscaping) {
						newString += '\\';
					}
					newString += json[i];
					alreadyEscaping = false;
					break;
				case '"':
					if (!alreadyEscaping) {
						newString += '\\';
					}
					newString += json[i];
					alreadyEscaping = false;
					break;
				default:
					newString += json[i];
					alreadyEscaping = false;
			}
		}

		return newString;
	}

	// this should stai in LogDbService, but there it creates a circular dependency
/*	saveLogsToBackend(logs: LogEntry[], sessionGuid: string) {
		const urlToCall = getSaveLogsUrl(sessionGuid);

		const result = this.callService.CallApi('POST', urlToCall, JSON.stringify(logs));

		return result;
	}
*/
}

const getCircularReplacer = () => {
	const seen = new WeakSet();
	return (key, value) => {
		if (typeof value === 'object' && value !== null) {
			if (seen.has(value)) {
				return;
			}
			seen.add(value);
		}
		return value;
	};
};

