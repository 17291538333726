import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticatedOnlyGuardService } from './core/guards/authenticated-only-guard.service';
import { GuestOnlyGuardService } from './core/guards/guest-only-guard.service';

export const APP_ROUTES: Routes = [
	{
		path: '',
		loadComponent: () => import('./container/home/home/home.component').then((m) => m.HomeComponent)
	},
	{
		path: 'home',
		loadComponent: () => import('./container/home/home/home.component').then((m) => m.HomeComponent)
	},
	{
		path: 'articolo',
		loadChildren: () => import('./container/article/article.routes').then((m) => m.ARTICLE_ROUTES)
	},
	{
		path: 'articolo-riservato',
		loadChildren: () => import('./container/article/article.routes').then((m) => m.ARTICLE_ROUTES)
	},
	
	{
		path: 'file',
		loadChildren: () => import('./container/file/file.routes').then((m) => m.FILE_ROUTES)
	},
	
	{
		path: 'dossier',
		loadChildren: () => import('./container/dossier/dossier.routes').then((m) => m.DOSSIER_ROUTES)
	},
    
	{
		path: 'video',
		loadChildren: () => import('./container/video/video.routes').then((m) => m.VIDEO_ROUTES)
	},
    
	{
		path: 'ecm',
		redirectTo: '/minisito/103469'
	},
	{
		path: 'minisito',
		loadChildren: () =>
			import('./container/dynamic-container/dynamic-container.routes').then(
				(m) => m.DYNAMICCONTAAINER_ROUTES
			)
	},
	{
		path: 'canale',
		loadChildren: () =>
			import('./container/dynamic-container/dynamic-container.routes').then(
				(m) => m.DYNAMICCONTAAINER_ROUTES
			)
	},
	
	{
		path: 'extra',
		loadChildren: () => import('./container/extra/extra.routes').then((m) => m.EXTRA_ROUTES)
	},
	{
		path: 'log',
		loadChildren: () => import('./container/log/log.routes').then((m) => m.LOG_ROUTES)
	},
	
	{
		path: 'profile',
		loadChildren: () => import('./container/profile/profile.routes').then((m) => m.PROFILE_ROUTES),
		canLoad: [AuthenticatedOnlyGuardService]
	},
	
	{
		path: 'signup',
		loadChildren: () => import('./container/signup/signup.routes').then((m) => m.SIGNUP_ROUTES),
		canLoad: [GuestOnlyGuardService]
	},
	
	{
		path: 'ricerca',
		loadChildren: () =>
			import('./container/search-list/search-list.routes').then((m) => m.SEARCHLIST_ROUTES)
	},
	
	{
		path: 'offline',
		loadChildren: () => import('./container/offline/offline.routes').then((m) => m.OFFLINE_ROUTES)
	},
	
	{
		path: 'fad',
		loadComponent: () =>
			import('./container/content-list/content-list/content-list.component').then((m) => m.ContentListComponent)
	},
	
	// every content detail without appropriate container is opened in iframe
	{
		path: 'fad/:contentId',
		loadChildren: () =>
			import('./container/iframe-container/iframe-container.routes').then(
				(m) => m.IFRAME_ROUTES
			)
	},
	{
		path: 'fad/:contentId/:titolo',
		loadChildren: () =>
		import('./container/iframe-container/iframe-container.routes').then(
			(m) => m.IFRAME_ROUTES
		)
	},
	{
		path: 'fad/:contentId/:titolo/:debug',
		loadChildren: () =>
		import('./container/iframe-container/iframe-container.routes').then(
			(m) => m.IFRAME_ROUTES
		)
	},
	{
		path: 'sondaggio/:contentId',
		loadChildren: () =>
		import('./container/iframe-container/iframe-container.routes').then(
			(m) => m.IFRAME_ROUTES
		)
	},
	{
		path: 'landing-page/:contentId',
		loadChildren: () =>
		import('./container/iframe-container/iframe-container.routes').then(
			(m) => m.IFRAME_ROUTES
		)
	},
	{
		path: 'landing-page/:contentId/:titolo',
		loadChildren: () =>
		import('./container/iframe-container/iframe-container.routes').then(
			(m) => m.IFRAME_ROUTES
		)
	},
	{
		path: 'mail',
		loadChildren: () =>
		import('./container/iframe-container/iframe-container.routes').then(
			(m) => m.IFRAME_ROUTES
		)
	},
	{
		path: 'pec',
		loadChildren: () =>
		import('./container/iframe-container/iframe-container.routes').then(
			(m) => m.IFRAME_ROUTES
		)
	},
	
	{
		path: 'chi-siamo',
		loadChildren: () =>
			import('./container/static-container/static-container.routes').then(
				(m) => m.STATIC_ROUTES
			)
	},
	{
		path: 'condizioni-utilizzo',
		loadChildren: () =>
		import('./container/static-container/static-container.routes').then(
			(m) => m.STATIC_ROUTES
		)
	},
	{
		path: 'privacy',
		loadChildren: () =>
		import('./container/static-container/static-container.routes').then(
			(m) => m.STATIC_ROUTES
		)
	},
	{
		path: 'condizioni-utilizzo-extra',
		loadChildren: () =>
		import('./container/static-container/static-container.routes').then(
			(m) => m.STATIC_ROUTES
		)
	},
	{
		path: 'redazione',
		loadChildren: () =>
		import('./container/static-container/static-container.routes').then(
			(m) => m.STATIC_ROUTES
		)
	},
	
	{
		path: 'settings',
		loadChildren: () => import('./container/settings/settings.routes').then((m) => m.SETTINGS_ROUTES)
	},
	
	{
		path: 'topic',
		loadChildren: () => import('./container/topic/topic.routes').then((m) => m.TOPIC_ROUTES)
	},
	
	{
		path: '**',
		loadChildren: () =>
			import('./container/page-not-found/page-not-found.routes').then((m) => m.PAGENOTFOUND_ROUTES)
	}
    
];

