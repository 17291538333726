import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../error/error-message.model';
import { LogEntry, LogLevel } from './log.model';

export const saveLogs = createAction(
	'[Log] SaveLogs',
	props<{ logLevel: LogLevel }>()
);
export const saveSingleLog = createAction(
	'[Log] SaveSingleLog',
	props<{ log: LogEntry }>()
);

export const saveLogsByDate = createAction(
	'[Log] SaveLogsByDate',
	props<{ startingDate: Date }>()
);
export const saveLogsSuccess = createAction(
	'[Log] SaveLogsSuccess',
	props<{ sessionGuid: string }>()
);
export const saveLogsFailure = createAction(
	'[Log] SaveLogsFailure',
	props<{ errorMessage: ErrorMessage }>()
);

export const deleteAllLogs = createAction('[Log] DeleteAllLogs');
export const deleteLogsSuccess = createAction(
	'[Log] DeleteLogsSuccess',
	);
export const deleteLogsFailure = createAction('[Log] DeleteLogsFailure');

export const countAllLogs = createAction(
	'[Log] CountAllLogs'
);

export const countAllLogsSuccess = createAction(
	'[Log] CountAllLogsSuccess',
	props<{ logCount: number }>()
);
export const countAllLogsFailure = createAction(
	'[Log] CountAllLogsFailure'
);			