//  The file contents for the current environment will overwrite these during build.
//  The build system defaults to the dev environment which uses `environment.ts`, but if you do
//  `ng build --configuration production` then `environment.prod.ts` will be used instead.
//  The list of which env maps to which file can be found in `.angular-cli.json`.
import { LogLevel } from '../app/domain/log/log.model';
import { IEnvironment } from './ienvironment';

import {
	globalClientDomainProd,
	globalServerDomainProd,
	globalAuthDomainProd
} from './base.domains';

const packageJson = require('../../../../package.json');

export const environment: IEnvironment = {
	//  Enables use of ng.profiler.timeChangeDetection(); in browser console
	enableDebugTools: false,
	appName: 'DottNet',
	envName: 'PROD',
	production: true,
	test: false,
	i18nPrefix: '',
	logLevel: LogLevel.DEBUG,
	clientDomain: globalClientDomainProd,
	backendDomain: globalServerDomainProd,
	apiDomain: globalServerDomainProd + '/api',
	authDomain: globalAuthDomainProd,
	loginUrl: '/login',
	softLoginUrl: '/softlogin',
	renewTokenUrl: '/renew',
	logoutUrl: '/logout',
	contentPath: 'https://content.dottnet.it',
	minimumLocalFreeSpace: 10, // mb
	adUnitCompanion: '/18651943/video-banner-test',
	adUnitBillboard: '/18651943/dn-billboard-new',
	adUnitSquare: '/18651943/DN_square',
  adUnitLivrea: '/18651943/skinpage',
  divLivrea: 'div-gpt-ad-1604578088358-0',
	divCompanion: 'companionAd',
	divCompanionMobile: 'companionAdMobile',
	divBillboard: 'div-gpt-ad-1521727898288-0',
	divSquare: 'div-gpt-ad-1521806802709-0',
	adTagUrlCompanion: 'https://pubads.g.doubleclick.net/gampad/ads?iu=',
	courseUrl:
		'https://moodle.dottnet.it/mqlogin/client_2.php?an={{idAnagrafica}}&idcontenuto={{courseId}}',
	surveyUrl:
		'https://sondaggi.dottnet.it/script/sondaggio_oauth2.php?idcontenuto={{surveyId}}&an={{idAnagrafica}}',
	landingPageUrl: '/landing-page/{{landingPageId}}/index.html',
	formUrl: 'https://form.dottnet.it/embed.php?id={{formId}}',
	privacyPolicyUrl: '/privacy',
	imprintUrl: '/condizioni-utilizzo',
	dottnetMinisitoWidth: 1200,
	zoomSdkKey: 'tx2Q7sFIaQNfoPn2sjUcBQkcqkY8LUxnTY8p',
	zoomMeetingNumber: '82854512425',
	zoomPasscode: 'merqurio',
	stripePkKey: 'pk_live_yQOW4ZZ3BiozQW4lhCfM9wdD00t5pSuig6',
	versions: {
		app: packageJson.version,
		angular: packageJson.dependencies['@angular/core'],
		ngrx: packageJson.dependencies['@ngrx/store'],
		material: packageJson.dependencies['@angular/material'],
		bootstrap: packageJson.dependencies.bootstrap,
		rxjs: packageJson.dependencies.rxjs,
		ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
		fontAwesome: packageJson.dependencies['@fortawesome/fontawesome-free-webfonts'],
		angularCli: packageJson.devDependencies['@angular/cli'],
		typescript: packageJson.devDependencies['typescript'],
		cypress: packageJson.devDependencies['cypress'],
		eslint: packageJson.devDependencies['eslint']
	},
	cookies: {
		essentialCookies: [
			{
				key: 'essential_cookie_manager',
				name: 'Essential Cookie Manager',
				description: 'dottnet.consent.essentialCookies.description',
				privacyPolicyUrl: 'dottnet.consent.essentialCookies.privacyPolicyUrl',
				cookies: []
			}
		],
		marketingCookies: [
			{
				key: 'marketing_google_analytics',
				name: 'dottnet.consent.marketingCookiesGa.name',
				description: 'dottnet.consent.marketingCookiesGa.description',
				privacyPolicyUrl: 'dottnet.consent.marketingCookiesGa.privacyPolicyUrl',
				cookies: [
					{
						name: '_ga',
						description: 'dottnet.consent.marketingCookiesGaGA.description',
						duration: 'dottnet.consent.marketingCookiesGaGA.duration'
					}
				]
			},
			{
				key: 'marketing_meta',
				name: 'dottnet.consent.marketingCookiesMeta.name',
				description: 'dottnet.consent.marketingCookiesMeta.description',
				privacyPolicyUrl: 'dottnet.consent.marketingCookiesMeta.privacyPolicyUrl',
				cookies: []
			}
		],
		functionalCookies: [
			{
				key: 'functional_google_analytics',
				name: 'dottnet.consent.functionalCookies.name',
				description: 'dottnet.consent.functionalCookies.description',
				privacyPolicyUrl: 'dottnet.consent.functionalCookies.privacyPolicyUrl',
				cookies: [
					{
						name: '_ga',
						description: 'dottnet.consent.functionalCookiesGA.description',
						duration: 'dottnet.consent.functionalCookiesGA.duration'
					},
					{
						name: '_gid',
						description: 'dottnet.consent.functionalCookiesGID.description',
						duration: 'dottnet.consent.functionalCookiesGID.duration'
					},
					{
						name: '_gat',
						description: 'dottnet.consent.functionalCookiesGAT.description',
						duration: 'dottnet.consent.functionalCookiesGAT.duration'
					},
					{
						name: '_gat_gtag_UA_XXXXXXXXX_1',
						description: 'dottnet.consent.functionalCookiesGTAG.description',
						duration: 'dottnet.consent.functionalCookiesGTAG.duration'
					},
					{
						name: 'AMP_TOKEN',
						description: 'dottnet.consent.functionalCookiesTOKEN.description',
						duration: 'dottnet.consent.functionalCookiesTOKEN.duration'
					}
				]
			}
		],
		showBadgeOpener: true,
		openerPosition: 'left-bottom',
		customClass: '',
		cookiePrefix: 'cookieconsent_',
		cookieExpiryDays: 365,
		showCookieDetails: true,
		showFunctionalCookies: true,
		showMarketingCookies: true,
		showEssentialCookies: true
	}
};
