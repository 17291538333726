import {
	mergeApplicationConfig,
	ApplicationConfig,
	TransferState,
	importProvidersFrom,
	PLATFORM_ID
} from '@angular/core';
import { provideServerRendering } from '@angular/platform-server';
import { appConfig } from './app.config';
import { XhrFactory } from '@angular/common';
import * as xhr2 from 'xhr2';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { APIKEY, ASSETSFOLDER } from '../tokens';
import { translateServerLoaderFactory } from './app/shared/loaders/translate-server.loader';
import { HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import { EnvironmentService } from './environments/environment.service';
import { Store } from '@ngrx/store';
import { LogService } from './app/core/log/log.service';
import { CallServiceServer } from './app/core/call-service/server/call.service.server';
import { CallService } from './app/core/call-service/browser/call.service';


export function callServiceFactory(
	http: HttpClient,
	environmentService: EnvironmentService,
	store: Store,
	logService: LogService,
	PLATFORM_ID,
	APIKEY,
	state: TransferState,
  translate: TranslateService
) {
	return new CallService(http, environmentService, store, logService, PLATFORM_ID, APIKEY, state,translate);
}


const clientConfig: ApplicationConfig = {
	providers: [
		{
			provide: APIKEY,
			useValue: ''
		},

		{
			provide: CallService,
			useFactory: callServiceFactory,
			deps: [HttpClient, EnvironmentService, Store, LogService, PLATFORM_ID, APIKEY, TransferState]
		},
	]
};

export const mergedConfig = mergeApplicationConfig(appConfig, clientConfig);
