import { createReducer, on } from '@ngrx/store';
import { DNOperationState } from '../../shared/util/util';
import { countAllLogs, countAllLogsSuccess, deleteLogsSuccess, saveLogs, saveLogsFailure, saveLogsSuccess } from './log.actions';
import { LogState } from './log.model';

const logInitialState: LogState = {
	//sessionGuid: '',
	error: undefined,
	logsOperationState: DNOperationState.INIT,
	logCount: 0
};

export const logReducer = createReducer(
	logInitialState,
	on(saveLogs, (state) => ({
		...logInitialState,
		logsOperationState: DNOperationState.LOADING
	})),
	on(saveLogsSuccess, (state, { sessionGuid }) => ({
		...state,
		//sessionGuid,
		logsOperationState: DNOperationState.OK
	})),
	on(saveLogsFailure, (state, { errorMessage: error }) => ({
		...state,
		error,
		logsOperationState: DNOperationState.ERROR
	})),
	on(countAllLogsSuccess, (state, { logCount:logCount }) => ({
		...state,
		logCount: logCount,
	})),
	on(deleteLogsSuccess, (state, ) => ({
		...state,
		logCount: 0
	}))
);
