import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../error/error-message.model';
import { Topic } from './topic.model';

export const loadTopic = createAction('[Topic] loadTopic');

export const loadTopicSuccess = createAction(
	'[Topic] loadTopicSuccess',
	props<{ datiTopic: Topic[] }>()
);

export const loadTopicFailure = createAction(
	'[Topic] loadTopicFailure',
	props<{ error: ErrorMessage }>()
);

export const loadCurrentTopic = createAction(
	'[Topic] loadCurrentTopic',
	props<{ topicId: number }>()
);

export const loadCurrentTopicSuccess = createAction(
	'[Topic] loadCurrentTopicSuccess',
	props<{ topic: Topic }>()
);
export const loadCurrentTopicFailure = createAction(
	'[Topic] loadCurrentTopicFailure',
	props<{ error: ErrorMessage }>()
);

export const clearCurrentTopic = createAction('[Content]  clearCurrentTopic');
