import {
	ContentPermission,
	PermissionVisibility
} from '../../shared/dialog/permission-dialog/permission.model';
export interface TemplateCt {
	templateID: number;
	templateDescription: string;
	contentTypeID: number; // id_tipo_oggetto
	contextTypeID: number; // id_tipo_contesto
	contextID: number; // id_contesto
	visibilita: PermissionVisibility;
	permessiDefault: ContentPermission;
	isContent: boolean; // contents can be shown directly
	isContainer: boolean; // containers can show other contents
	prerender: boolean;
  extendedProfile: string;
  livreaAttivo: boolean;
	// parametriToken: string;
	// idStrutturaDetail: number;
	// idStrutturaList: number;
	// isContent: boolean;
	// isEsterno: boolean;
	// isContenitore: boolean;
	// isLista: boolean;
	// hasStruttura: boolean;
	// sitemap: boolean;
	// idCategoria: number;
	// showTemplate: boolean;
	// livreaDataInizio: Date;
	// livreaDataFine: Date;
	// descrizioneCategoria: string;
}

export enum TemplateTypeId {
	ARTICOLO = 1,
	CONTENUTO = 2,
	VIDEO = 4,
	GALLERY = 5,
	FILE = 13,
	CANALE = 15,
	MINISITO = 16,
	BLOG = 17,
	FAD = 34,
	SONDAGGIO = 38,
	PROFILO = 39,
	PEC = 69,
	MAIL = 70,
	EXTRA = 156,
	DOSSIER = 159,
	SIGNIN = 162,
	SIGNUP = 163,
	RICERCA = 88,
	HOME = 90,
	TOPIC = 172,
  ARTICOLORISERVATO = 72
}

export const ARTICLE_VIDEO_IDS = [1, 4];

// export enum TemplateType {
// 	articolo = 'articolo',
// 	contenuto = 'contenuto',
// 	video = 'video',
// 	gallery = 'gallery',
// 	file = 'file',
// 	canale = 'canale',
// 	minisito = 'minisito',
// 	blog = 'blog',
// 	fad = 'fad',
// 	sondaggio = 'sondaggio',
// 	profilo = 'profilo',
// 	pec = 'pec',
// 	mail = 'mail',
// 	extra = 'extra',
// 	dossier = 'dossier'
// }

// index signature
export interface DottnetTemplate {
	readonly [key: string]: TemplateCt;
}

export const getArticleTemplate = (): TemplateCt => templateCtMap.articolo;
export const getThreadTemplate = (): TemplateCt => templateCtMap.thread;

export const getTemplateFromDescription = (desc: string): TemplateCt =>
	Object.entries(templateCtMap)
		.map((templatePair) => templatePair[1])
		.filter((template) => template.templateDescription === desc)?.[0];

export const getTemplateFromId = (id: number): TemplateCt =>
	Object.entries(templateCtMap)
		.map((templatePair) => templatePair[1])
		.filter((template) => template.templateID === id)?.[0];

export const templateCtMap: DottnetTemplate = {
	articolo: <TemplateCt>{
		isContent: true,
		isContainer: false,
		templateDescription: 'articolo',
		contextTypeID: 33,
		contextID: 93658,
		templateID: 1,
		contentTypeID: 1,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: true,
    extendedProfile: '',
    livreaAttivo: true
	},
	'articolo-illustrato': <TemplateCt>{
		isContent: true,
		isContainer: false,
		templateDescription: 'articolo-illustrato',
		contextTypeID: 0,
		contextID: 0,
		templateID: 9,
		contentTypeID: 1,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: true
	},
	'articolo-riservato': <TemplateCt>{
		isContent: true,
		isContainer: false,
		templateDescription: 'articolo-riservato',
		contextTypeID: 0,
		contextID: 0,
		templateID: 72,
		contentTypeID: 1,
		visibilita: PermissionVisibility.RESTRICTIONS,
		permessiDefault: ContentPermission.CLOSED,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},
	blog: <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'blog',
		contextTypeID: 42,
		contextID: -1,
		templateID: 17,
		contentTypeID: 42,
		visibilita: PermissionVisibility.RESTRICTIONS,
		permessiDefault: ContentPermission.OPEN_SOFT,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},
	'blog-associazione-pazienti': <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'blog-associazione-pazienti',
		contextTypeID: 42,
		contextID: -1,
		templateID: 36,
		contentTypeID: 42,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},
	'blog-malattie-rare': <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'blog-malattie-rare',
		contextTypeID: 42,
		contextID: -1,
		templateID: 37,
		contentTypeID: 42,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},
	'blog-professionale': <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'blog-professionale',
		contextTypeID: 42,
		contextID: -1,
		templateID: 35,
		contentTypeID: 42,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},
	canale: <TemplateCt>{
		isContent: false,
		isContainer: true,
		templateDescription: 'canale',
		contextTypeID: 33,
		contextID: -1,
		templateID: 15,
		contentTypeID: 33,
		visibilita: PermissionVisibility.RESTRICTIONS,
		permessiDefault: ContentPermission.OPEN_SOFT,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},

	'canale-minisito': <TemplateCt>{
		isContent: false,
		isContainer: true,
		templateDescription: 'canale-minisito',
		contextTypeID: 33,
		contextID: -1,
		templateID: 49,
		contentTypeID: 33,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},
	'chi-siamo': <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'chi-siamo',
		contextTypeID: 0,
		contextID: -1,
		templateID: 178,
		contentTypeID: 45,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},
	'condizioni-utilizzo': <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'condizioni-utilizzo',
		contextTypeID: 0,
		contextID: -1,
		templateID: 176,
		contentTypeID: 45,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},

	dossier: <TemplateCt>{
		isContent: true,
		isContainer: false,
		templateDescription: 'dossier',
		contextTypeID: 5,
		contextID: 0,
		templateID: 159,
		contentTypeID: 1,
		visibilita: PermissionVisibility.RESTRICTIONS,
		permessiDefault: ContentPermission.OPEN_SOFT,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: true
	},
	'e-book': <TemplateCt>{
		isContent: true,
		isContainer: false,
		templateDescription: 'e-book',
		contextTypeID: 46,
		contextID: 0,
		templateID: 68,
		contentTypeID: 1,
		visibilita: PermissionVisibility.SOFT_LOGGED,
		permessiDefault: ContentPermission.OPEN_SOFT,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},
	extra: <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'extra',
		contextTypeID: 47,
		contextID: 0,
		templateID: 156,
		contentTypeID: 47,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: true
	},
	fad: <TemplateCt>{
		isContent: true,
		isContainer: false,
		templateDescription: 'fad',
		contextTypeID: 30,
		contextID: 0,
		templateID: 34,
		contentTypeID: 46,
		visibilita: PermissionVisibility.RESTRICTIONS,
		permessiDefault: ContentPermission.CLOSED,
		prerender: false,
    extendedProfile: 'ecm', // must have same name of profile group setted in the database
    livreaAttivo: false
	},
	file: <TemplateCt>{
		isContent: true,
		isContainer: false,
		templateDescription: 'file',
		contextTypeID: 0,
		contextID: 0,
		templateID: 13,
		contentTypeID: 5,
		visibilita: PermissionVisibility.RESTRICTIONS,
		permessiDefault: ContentPermission.OPEN_SOFT,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: true
	},

	home: <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'home',
		contextTypeID: 0,
		contextID: 0,
		templateID: 90,
		contentTypeID: 1,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: true
	},

	'landing-page': <TemplateCt>{
		isContent: true,
		isContainer: true,
		templateDescription: 'landing-page',
		contextTypeID: 0,
		contextID: 0,
		templateID: 61,
		contentTypeID: 35,
		visibilita: PermissionVisibility.RESTRICTIONS,
		permessiDefault: ContentPermission.OPEN_SOFT,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},

	log: <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'log',
		contextTypeID: 0,
		contextID: 0,
		templateID: 170,
		contentTypeID: 48,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},

	mail: <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'mail',
		contextTypeID: 0,
		contextID: 0,
		templateID: 70,
		contentTypeID: 46,
		visibilita: PermissionVisibility.HARD_LOGGED,
		permessiDefault: ContentPermission.OPEN_HARD,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},

	minisito: <TemplateCt>{
		isContent: false,
		isContainer: true,
		templateDescription: 'minisito',
		contextTypeID: 34,
		contextID: -1,
		templateID: 16,
		contentTypeID: 34,
		visibilita: PermissionVisibility.RESTRICTIONS,
		permessiDefault: ContentPermission.CLOSED,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},

	pec: <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'pec',
		contextTypeID: 0,
		contextID: 0,
		templateID: 69,
		contentTypeID: 46,
		visibilita: PermissionVisibility.HARD_LOGGED,
		permessiDefault: ContentPermission.OPEN_HARD,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},
	privacy: <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'privacy',
		contextTypeID: 0,
		contextID: -1,
		templateID: 135,
		contentTypeID: 45,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},
	redazione: <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'redazione',
		contextTypeID: 0,
		contextID: -1,
		templateID: 177,
		contentTypeID: 45,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},

	sondaggio: <TemplateCt>{
		isContent: true,
		isContainer: false,
		templateDescription: 'sondaggio',
		contextTypeID: 0,
		contextID: 0,
		templateID: 38,
		contentTypeID: 46,
		visibilita: PermissionVisibility.RESTRICTIONS,
		permessiDefault: ContentPermission.CLOSED,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},

	static: <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'static',
		contextTypeID: 0,
		contextID: 0,
		templateID: 50,
		contentTypeID: 35,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},
	thread: <TemplateCt>{
		isContent: true,
		isContainer: false,
		templateDescription: 'thread',
		contextTypeID: 0,
		contextID: 0,
		templateID: 83,
		contentTypeID: 24,
		visibilita: 3,
		permessiDefault: 4,
    livreaAttivo: false
	},

	video: <TemplateCt>{
		isContent: true,
		isContainer: false,
		templateDescription: 'video',
		contextTypeID: 0,
		contextID: 93755,
		templateID: 4,
		contentTypeID: 18,
		visibilita: PermissionVisibility.RESTRICTIONS,
		permessiDefault: ContentPermission.OPEN_SOFT,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: true
	},

	'video-youtube': <TemplateCt>{
		isContent: true,
		isContainer: false,
		templateDescription: 'video-youtube',
		contextTypeID: 16,
		contextID: 0,
		templateID: 58,
		contentTypeID: 18,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN_SOFT,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},
	signin: <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'signin',
		contextTypeID: 0,
		contextID: 0,
		templateID: 162,
		contentTypeID: 0,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},
	signup: <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'signup',
		contextTypeID: 0,
		contextID: 0,
		templateID: 163,
		contentTypeID: 0,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},
	ricerca: <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'ricerca',
		contextTypeID: 0,
		contextID: 0,
		templateID: 88,
		contentTypeID: 44,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: true
	},
	topic: <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'topic',
		contextTypeID: 0,
		contextID: 93658,
		templateID: 172,
		contentTypeID: 1,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: true
	},
	zoom: <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'zoom',
		contextTypeID: 0,
		contextID: 0,
		templateID: 168,
		contentTypeID: 44,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},
	settings: <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'settings',
		contextTypeID: 0,
		contextID: 0,
		templateID: 173,
		contentTypeID: 49,
		visibilita: PermissionVisibility.OPEN,
		permessiDefault: ContentPermission.OPEN,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: false
	},
	profile: <TemplateCt>{
		isContent: false,
		isContainer: false,
		templateDescription: 'profile',
		contextTypeID: 0,
		contextID: 0,
		templateID: 39,
		contentTypeID: 44,
		visibilita: PermissionVisibility.HARD_LOGGED,
		permessiDefault: ContentPermission.OPEN_HARD,
		prerender: false,
    extendedProfile: '',
    livreaAttivo: true
	}
};
