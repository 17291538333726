<!-- eslint-disable @angular-eslint/template/use-track-by-function -->
<mat-toolbar color="primary" class="flex-row mobile-toolbar-wrapper">
	<button mat-icon-button (click)="sidenav.open()" color="accent">
		<mat-icon color="accent">menu</mat-icon>
	</button>

	<div class="flex-col">
		<span class="mobile-toolbar">
			<a
				class="mobile-toolbar--logo-container flex-row flex-justify-start flex-align-center"
				routerLink="home"
			>
				<img class="mobile-toolbar--logo" [src]="logo" [alt]="logo" />
			</a>
		</span>

		<span *ngIf="topic" class="mobile-menu-topic">{{ topic.topicDescription | titlecase }}</span>
	</div>
</mat-toolbar>

<mat-toolbar color="primary" class="custom-desktop-toolbar">
	<div class="topRow flex-row flex-justify-space-evenly flex-align-center">
		<mat-form-field id="searchField">
			<mat-label>{{ 'dottnet.menu.ricerca' | translate }}</mat-label>
			<input
				matInput
				type="text"
				minlength="3"
				[(ngModel)]="searchText"
				(keydown.enter)="searchTextResult()"
				placeholder="{{ 'dottnet.search.toofewcharacters' | translate }}"
			/>
			<button
				mat-icon-button
				matSuffix
				aria-label="Search"
				[disabled]="!checkMinLength()"
				(click)="searchTextResult()"
			>
				<mat-icon>search</mat-icon>
			</button>
			<mat-error>{{ 'dottnet.search.toofewcharacters' | translate }}</mat-error>
		</mat-form-field>

		<div class="flex-col">
			<span class="branding flex-row flex-justify-center flex-align-center">
				<a routerLink="home">
					<img class="browser-logo" [src]="logo" [alt]="logo" />
				</a>
			</span>

			<span *ngIf="topic" class="menu-topic">{{ topic.topicDescription | titlecase }}</span>
		</div>

		<div class="flex-row flex-justify-end flex-align-center">
			<ng-container *ngIf="user">
				<img class="icona-premium" *ngIf="user?.premium" src="assets/premium.png" />
			</ng-container>
			<button id="accediButton" mat-button [matMenuTriggerFor]="toolbarUserMenu">
				<span class="flex-row flex-justify-start flex-align-center navigation-button">
					{{ getUserName() }}
					<mat-icon color="accent">arrow_drop_down</mat-icon>
				</span>
			</button>

			<mat-menu #toolbarUserMenu="matMenu" xPosition="before" class="dottnet-menu">
				<span *ngFor="let userMenuItem of userMenuItems" class="user-menu-item">
					<a
						mat-menu-item
						class="navigation-button"
						[routerLink]="userMenuItem.routerLink"
						(click)="doAction(userMenuItem)"
					>
						{{ userMenuItem.label | translate }}
						<mat-icon *ngIf="userMenuItem.logoutIcon"> power_settings_new </mat-icon>
					</a>
				</span>
			</mat-menu>
		</div>
	</div>
	<!--SOLO PER DEBUGGARE-->
	<!-- <div fxLayoutAlign="center center" class="debug-div">
		<section *ngIf="authLoginState">LS: {{ authLoginState.toString() }}</section>
		&nbsp;&nbsp;
		<section>
			SI: <span *ngIf="sessionID$ | async as sessionID">{{ sessionID.toString() }}</span>
		</section>
		&nbsp;&nbsp;
		<section *ngIf="loginType$ | async as loginType">LT: {{ loginType }}</section>
	</div> -->
	<!--FINE SOLO PER DEBUGGARE-->

	<hr class="menu-hr" />

	<div>
		<ng-container *ngFor="let item of navigation">
			<ng-container *ngIf="item.subLinks">
				<button
					style="min-width: 110px; text-align: left"
					mat-button
					[matMenuTriggerFor]="toolbarUserMenu"
				>
					<span class="flex-row flex-justify-start flex-align-center navigation-button"
						>{{ item.label | translate }}
						<mat-icon color="accent">arrow_drop_down</mat-icon>
					</span>
				</button>

				<mat-menu #toolbarUserMenu="matMenu" xPosition="before" class="dottnet-menu">
					<ng-container *ngIf="user?.premium && item.link === 'extra'">
						<span class="navigation-button">
							<a
								mat-menu-item
								*ngFor="let subItem of item.subLinksLogged"
								[routerLink]="[subItem.link]"
							>
								{{ subItem.label | translate }}
							</a>
						</span>
					</ng-container>

					<ng-container *ngIf="!user?.premium && item.link === 'extra'">
						<span class="navigation-button">
							<a mat-menu-item *ngFor="let subItem of item.subLinks" [routerLink]="[subItem.link]">
								{{ subItem.label | translate }}
							</a>
						</span>
					</ng-container>

					<ng-container *ngIf="item.link !== 'extra'">
						<span class="navigation-button">
							<a mat-menu-item *ngFor="let subItem of item.subLinks" [routerLink]="[subItem.link]">
								{{ subItem.label | translate }}
							</a>
						</span>
					</ng-container>
				</mat-menu>
			</ng-container>

			<ng-container *ngIf="!item.subLinks">
				<span class="navigation-button">
					<a [routerLink]="[item.link]" style="min-width: 110px" mat-button>
						{{ item.label | translate }}
					</a>
				</span>
			</ng-container>
		</ng-container>
	</div>
</mat-toolbar>
