import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../error/error-message.model';
import { ProfessionType } from './profession.model';

export const loadProfession = createAction('[Profession] LoadProfession');

export const loadProfessionSuccess = createAction(
	'[Profession] loadProfessionSuccess',
	props<{ datiProfession: ProfessionType[] }>()
);
export const loadProfessionFailure = createAction(
	'[Profession] loadProfessionFailure',
	props<{ error: ErrorMessage }>()
);
