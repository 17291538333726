import { ParamMap, Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../../domain/error/error-message.model';

export const createSession = createAction(
	'[Session] CreateSession',
	props<{ params: ParamMap; oldSessionID: number; url: string; referrer: string }>()
);
export const createSessionSuccess = createAction(
	'[Session] CreateSessionSuccess',
	props<{ sessionID: number }>()
);

export const createSessionFailure = createAction(
	'[Session] CreateSessionFailure',
	props<{ error: ErrorMessage }>()
);

export const resetSession = createAction('[Session] ResetSession');

export const createHardLoginSession = createAction(
	'[Session] CreateHardLoginSession',
	props<{ params: ParamMap; oldSessionID: number; url: string; referrer: string }>()
);
export const createHardLoginSessionSuccess = createAction(
	'[Session] CreateHardLoginSessionSuccess',
	props<{ sessionID: number }>()
);

export const createHardLoginSessionFailure = createAction(
	'[Session] CreateHardLoginSessionFailure',
	props<{ error: ErrorMessage }>()
);
