import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { getAge } from './util';

const passwordRequirement = {
	passwordMinLowerCase: 1,
	passwordMinNumber: 1,
	passwordMinSymbol: 1,
	passwordMinUpperCase: 1,
	passwordMinCharacters: 8
};

const passwordPattern = [
	`(?=([^a-z]*[a-z])\{${passwordRequirement.passwordMinLowerCase},\})`,
	`(?=([^A-Z]*[A-Z])\{${passwordRequirement.passwordMinUpperCase},\})`,
	`(?=([^0-9]*[0-9])\{${passwordRequirement.passwordMinNumber},\})`,
	`(?=(\.\*[\$\@\$\!\%\*\?\& ])\{${passwordRequirement.passwordMinSymbol},\})`,
	`[A-Za-z\\d\$\@\$\!\%\*\?\&\. ]{${passwordRequirement.passwordMinCharacters},}`
].join('');

const cfPattern = /^.{16}$/;

export const dateValidator =
	(): ValidatorFn =>
	(control: AbstractControl): ValidationErrors | null =>
		getAge(control?.value) < 18 ? { invalidAge: true } : null;

export const passwordValidator = (): ValidatorFn => Validators.pattern(passwordPattern);
export const cfLengthValidator = (): ValidatorFn => Validators.pattern(cfPattern);

export const checkSamePassword =
	(): ValidatorFn =>
	(controlForm: AbstractControl): ValidationErrors | null => {
		const password = controlForm.get('newPassword');
		const confirmPassword = controlForm.get('confirmNewPassword');

		if (password && confirmPassword && password.value !== confirmPassword.value) {
			confirmPassword.setErrors({
				passwordMismatch: true
			});
		} else {
			confirmPassword.setErrors(null);
		}

		return password && confirmPassword && password.value !== confirmPassword.value
			? { passwordMismatch: true }
			: null;
	};

export const autocompleteObjectValidator =
	(): ValidatorFn =>
	(controlForm: AbstractControl): ValidationErrors | null => {
		if (isNaN(controlForm.value[Object.keys(controlForm.value)[0]])) {
			return { emptyObject: true };
		} else {
			return null;
		}
	};
