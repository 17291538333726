/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Category } from '../../domain/category/category.model';
import { City } from '../../domain/city/city.model';
import { Province } from '../../domain/province/province.model';
import { Specialty } from '../../domain/specialty/specialty.model';
import { ListaGruppiEnum } from '../../domain/user/user.model';
import { UserService } from '../../domain/user/user.service';
import { autocompleteObjectValidator, cfLengthValidator, dateValidator, passwordValidator } from '../util/validators';
import {
	UserForm,
	UserFormConsentsForm,
	UserFormEcmInfos,
	UserFormPersonalInfos,
	UserFormProfessionalInfos,
	UserFormProfessionalInfosRegisterForm
} from './user-form.model';
import { ProfessionType } from '../../domain/profession/profession.model';

const codiciMese = ['A', 'B', 'C', 'D', 'E', 'H', 'L', 'M', 'P', 'R', 'S', 'T'];

@Injectable({ providedIn: 'root' })
export class UserFormService {
	constructor(
		private ts: TranslateService,
		private fb: FormBuilder,
		private userService: UserService,
		private store: Store
	) {}

	buildRegisterUserForm(): FormGroup<UserForm> {
		return this.fb.group<UserForm>({
			personalInfos: this.fb.group<UserFormPersonalInfos>({
				name: new FormControl<string | null>('', { validators: [Validators.required] }),
				surname: new FormControl<string | null>('', { validators: [Validators.required] }),
				email: new FormControl<string | null>('', {
					validators: [Validators.required, Validators.email],
					asyncValidators: [this.userService.checkMailExistence()],
					updateOn: 'blur'
				}),
				password: new FormControl<string | null>('', {
					validators: [Validators.required, passwordValidator()]
				}),
				birthdate: new FormControl<string | null>('', {
					validators: [Validators.required, dateValidator()]
				}),
				gender: new FormControl<string | null>(null, { validators: [Validators.required] }),
				bornAbroad: new FormControl<string | null>(null, { validators: [Validators.required] }),
				birthplace: new FormControl<City | null>(<City>{}, {
					validators: [autocompleteObjectValidator(), Validators.required]
				}),
				codiceFiscale: new FormControl<string | null>(
					'',
					{
						validators: [Validators.required,cfLengthValidator()],
						asyncValidators: [this.userService.checkCFExistence(this.store)],
						// updateOn: 'change'
					}
				)
			}),

      ecmInfos: this.fb.group<UserFormEcmInfos>({
        cellulare: new FormControl<string | null>(''),
        cap: new FormControl<string | null>(''),
        professionPlace: new FormControl<City | null>(<City>{}),
        professionType: new FormControl<ProfessionType | null>(<ProfessionType>{}),
      }),

			professionalInfos: this.fb.group<UserFormProfessionalInfos>({
				category: new FormControl<Category | null>(<Category>{}, {
					validators: [autocompleteObjectValidator(), Validators.required]
				}),
				specialty: new FormControl<Specialty | null>(<Specialty>{}),

				registerForm: this.fb.group<UserFormProfessionalInfosRegisterForm>({
					registerNumber: new FormControl<string | null>(''),
					registerProvince: new FormControl<Province | null>(<Province>{})
				})
			}),

			consentsForm: this.fb.group<UserFormConsentsForm>(
				{
					marketing: new FormControl<string | null>('', { validators: [Validators.required] }),
					marketingThirdParties: new FormControl<string | null>('', {
						validators: [Validators.required]
					}),
					profiling: new FormControl<string | null>('', { validators: [Validators.required] }),
					profilingThirdParties: new FormControl<string | null>('', {
						validators: [Validators.required]
					})
				},
				// Fire validation only when submit
				{ updateOn: 'submit' }
			)
		});
	}

	buildEditUserForm(listaGruppi: ListaGruppiEnum[]): FormGroup<UserForm> {
		let formGroup: FormGroup<UserForm>;

		if (listaGruppi.length > 0) {
			formGroup = this.fb.group<UserForm>({
				personalInfos: this.fb.group<UserFormPersonalInfos>({
					name: new FormControl<string | null>(''),
					surname: new FormControl<string | null>(''),
					email: new FormControl<string | null>(''),
					password: new FormControl<string | null>(''),
					birthdate: new FormControl<string | null>(''),
					gender: new FormControl<string | null>(''),
					bornAbroad: new FormControl<string | null>(''),
					birthplace: new FormControl<City | null>(<City>{}),
					codiceFiscale: new FormControl<string | null>(
						'',
						{
							asyncValidators: [this.userService.checkCFExistence(this.store)],
							// updateOn: 'change'
						}
					)
				}),
        ecmInfos: this.fb.group<UserFormEcmInfos>({
          cellulare: new FormControl<string | null>(''),
          cap: new FormControl<string | null>(''),
          professionPlace: new FormControl<City | null>(<City>{}),
          professionType: new FormControl<ProfessionType | null>(<ProfessionType>{}),
        }),
				professionalInfos: this.fb.group<UserFormProfessionalInfos>({
					category: new FormControl<Category | null>(<Category>{}),
					specialty: new FormControl<Specialty | null>(<Specialty>{}),
					registerForm: this.fb.group<UserFormProfessionalInfosRegisterForm>({
						registerNumber: new FormControl<string | null>(''),
						registerProvince: new FormControl<Province | null>(<Province>{})
					})
				}),
				consentsForm: this.fb.group<UserFormConsentsForm>({
					marketing: new FormControl<string | null>(''),
					marketingThirdParties: new FormControl<string | null>(''),
					profiling: new FormControl<string | null>(''),
					profilingThirdParties: new FormControl<string | null>('')
				})
			});

			if (listaGruppi.includes(ListaGruppiEnum.Consents)) {
				formGroup.controls.consentsForm.controls['marketing'].setValidators([Validators.required]);
				formGroup.controls.consentsForm.controls['marketingThirdParties'].setValidators([
					Validators.required
				]);
				formGroup.controls.consentsForm.controls['profiling'].setValidators([Validators.required]);
				formGroup.controls.consentsForm.controls['profilingThirdParties'].setValidators([
					Validators.required
				]);
			}
			if (listaGruppi.includes(ListaGruppiEnum.Personal)) {
				formGroup.controls.personalInfos.controls['name'].setValidators([Validators.required]);
				formGroup.controls.personalInfos.controls['surname'].setValidators([Validators.required]);
				formGroup.controls.personalInfos.controls['email'].setValidators([
					Validators.required,
					Validators.email
				]);
				// formGroup.controls.personalInfos.controls['password'].setValidators([
				// 	Validators.required,
				// 	passwordValidator()
				// ]);
				formGroup.controls.personalInfos.controls['birthdate'].setValidators([
					Validators.required,
					dateValidator()
				]);
				formGroup.controls.personalInfos.controls['gender'].setValidators([Validators.required]);
				formGroup.controls.personalInfos.controls['bornAbroad'].setValidators([
					Validators.required
				]);
				formGroup.controls.personalInfos.controls['birthplace'].setValidators([
					Validators.required
				]);
				formGroup.controls.personalInfos.controls['codiceFiscale'].setValidators([
					Validators.required,cfLengthValidator()
				]);
			}
			if (listaGruppi.includes(ListaGruppiEnum.Professional)) {
				formGroup.controls.professionalInfos.controls['category'].setValidators([
					Validators.required
				]);
			}
		} else {
			formGroup = this.fb.group<UserForm>({
				personalInfos: this.fb.group<UserFormPersonalInfos>({
					name: new FormControl<string | null>('', { validators: [Validators.required] }),
					surname: new FormControl<string | null>('', { validators: [Validators.required] }),
					email: new FormControl<string | null>('', {
						validators: [Validators.required, Validators.email]
					}),
					password: new FormControl<string | null>(''),
					birthdate: new FormControl<string | null>('', {
						validators: [Validators.required, dateValidator()]
					}),
					gender: new FormControl<string | null>('', { validators: [Validators.required] }),
					bornAbroad: new FormControl<string | null>('', { validators: [Validators.required] }),
					birthplace: new FormControl<City | null>(<City>{}, { validators: [Validators.required] }),
					codiceFiscale: new FormControl<string | null>(
						'',
						{
							validators: [Validators.required,cfLengthValidator()],
							asyncValidators: [this.userService.checkCFExistence(this.store)],
							// updateOn: 'change'
						}
					)
				}),
        ecmInfos: this.fb.group<UserFormEcmInfos>({
          cellulare: new FormControl<string | null>(''),
          cap: new FormControl<string | null>(''),
          professionPlace: new FormControl<City | null>(<City>{}),
          professionType: new FormControl<ProfessionType | null>(<ProfessionType>{}),
        }),
				professionalInfos: this.fb.group<UserFormProfessionalInfos>({
					category: new FormControl<Category | null>(<Category>{}),
					specialty: new FormControl<Specialty | null>(<Specialty>{}),

					registerForm: this.fb.group<UserFormProfessionalInfosRegisterForm>({
						registerNumber: new FormControl<string | null>(''),
						registerProvince: new FormControl<Province | null>(<Province>{})
					})
				}),

				consentsForm: this.fb.group<UserFormConsentsForm>(
					{
						marketing: new FormControl<string | null>('', { validators: [Validators.required] }),
						marketingThirdParties: new FormControl<string | null>('', {
							validators: [Validators.required]
						}),
						profiling: new FormControl<string | null>('', { validators: [Validators.required] }),
						profilingThirdParties: new FormControl<string | null>('', {
							validators: [Validators.required]
						})
					}
					// ,
					// // Fire validation only when submit
					// { updateOn: 'submit' }
				)
			});
		}

		return formGroup;
	}

	// Util function to get every error message from the translation service
	/**
	 *
	 * @param form The form we are applying the validation
	 * @param field The field we are checking. Must match the pattern 'dottnet.label.xxx'
	 * @param formError If the error is to be looked for into the form itself (cross-field validation)
	 * @returns The error string corresponding to the error applied to the control
	 */
	buildErrorMessage<T extends { [K in keyof T]: AbstractControl<any, any> }>(
		form: FormGroup<T>,
		field: string
	): string {
		const splitted: string[] = field.split('.');
		let fieldName, formGroup, patternText: string;
		let formControl: FormControl;

		// Casi particolari
		if (field === 'consentsForm') {
			return this.ts.instant('dottnet.field.error.consentsForm');
		}

		if (splitted?.[1] === 'registerForm') {
			return this.ts.instant('dottnet.field.error.registerForm');
		}

		if (splitted.length > 1) {
			fieldName = splitted[splitted.length - 1];
			formGroup = splitted[splitted.length - 2];

			formControl = form.get(formGroup).get(fieldName) as FormControl;
		} else {
			fieldName = formGroup = splitted[0];
			formControl = form.get(fieldName) as FormControl;
		}

		if (
			splitted?.[1] === 'birthplace' ||
			splitted?.[1] === 'category' ||
			splitted?.[1] === 'specialty'||
			splitted?.[1] === 'professionPlace'||
			splitted?.[1] === 'professionType'
		) {
			this.ts.instant('dottnet.field.error.emptyObject');
		}

    if (formControl.hasError('pattern')){
      if (fieldName === 'password'){
        patternText = this.ts.instant('dottnet.field.error.password');
      }

      if (fieldName === 'codiceFiscale'){
        patternText = this.ts.instant('dottnet.field.error.cfLength');
      }

      if (fieldName === 'cellulare'){
        patternText = this.ts.instant('dottnet.field.error.cellulare');
      }

      if (fieldName === 'cap'){
        patternText = this.ts.instant('dottnet.field.error.numeric');
      }
    }


		return formControl.hasError('required')
			? `${this.ts.instant(`dottnet.label.${fieldName}`)} ${this.ts.instant(
					'dottnet.field.error.required'
			  )}`
			: formControl.hasError('email')
			? `${this.ts.instant('dottnet.field.error.email')}`
			: formControl.hasError('invalidAge')
			? `${this.ts.instant('dottnet.field.error.invalidAge')}`
			: formControl.hasError('pattern')
			? patternText
			: formControl.hasError('mailExistence')
			? `${this.ts.instant('dottnet.field.error.mailExistence')}`
			: formControl.hasError('cfExistence')
			? `${this.ts.instant('dottnet.field.error.cfExistence')}`
			: formControl.hasError('passwordMismatch')
			? `${this.ts.instant('dottnet.field.error.passwordMismatch')}`
			: formControl.hasError('emptyObject')
			? `${this.ts.instant('dottnet.field.error.emptyObject')}`
			: '';
	}

	buildCodiceFiscale(nome: string, cognome: string, city: City, dataString: string, sesso: string) {
		let consonantiNome: string[] = <string[]>[];
		let vocaliNome: string[] = <string[]>[];
		let consonantiCognome: string[] = <string[]>[];
		let vocaliCognome: string[] = <string[]>[];
		let consonanti: string[] = <string[]>[];
		let vocali: string[] = <string[]>[];
		let consonantiPreseNome: string = '';
		let consonantiPreseCognome: string = '';

		let nCons: number = 0;

		const dataNascita: Date = new Date(dataString);
		const anno: string = dataNascita.getFullYear().toString().slice(-2);
		let mese: string = dataNascita.getMonth() + 1 + '';
		let giorno = dataNascita.getDate() + '';

		if (nome.length < 3) {
			nome += 'x';
		} else {
			for (let i = 0; i <= nome.length; i++) {
				if (nome.charAt(i).match(/[aeiouAEIOU]/)) {
					vocaliNome[i] = nome.charAt(i).toUpperCase();
					vocali = vocaliNome.filter(Boolean);
				} else if (nome.charAt(i).match(/[\',\"\s]/)) {
					continue;
				} else {
					consonantiNome[i] = nome.charAt(i).toUpperCase();
					consonanti = consonantiNome.filter(Boolean);

					if (consonantiNome[i] !== '') nCons++;
				}
			}
		}

		for (let i = 0; i <= nome.length; i++) {
			if (nome.charAt(i).match(/[aeiouAEIOU]/)) {
				vocaliNome[i] = nome.charAt(i).toUpperCase();
				vocali = vocaliNome.filter(Boolean);
			} else if (nome.charAt(i).match(/[\',\"\s]/)) {
				// REGEX CHE TOGLIE VIRGOLE E APOSTROFI
				continue;
			} else {
				consonantiNome[i] = nome.charAt(i).toUpperCase();
				consonanti = consonantiNome.filter(Boolean);

				if (consonantiNome[i] !== '') nCons++;
			}
		}

		if (consonanti.length >= 4) {
			consonantiPreseNome = consonanti?.[0] + consonanti?.[2] + consonanti?.[3];
		} else if (consonanti.length === 1) {
			consonantiPreseNome = consonanti?.[0] + vocali?.[0] + vocali?.[1];
		} else if (consonanti.length < 3) {
			consonantiPreseNome = consonanti?.[0] + consonanti?.[1] + vocali?.[0];
		} else if (consonanti.length === 3) {
			consonantiPreseNome = consonanti?.[0] + consonanti?.[1] + consonanti?.[2];
		}

		if (consonanti.length >= 4) {
			consonantiPreseNome = consonanti?.[0] + consonanti?.[2] + consonanti?.[3];
		} else if (consonanti.length === 1) {
			consonantiPreseNome = consonanti?.[0] + vocali?.[0] + vocali?.[1];
		} else if (consonanti.length < 3) {
			consonantiPreseNome = consonanti?.[0] + consonanti?.[1] + vocali?.[0];
		} else if (consonanti.length === 3) {
			consonantiPreseNome = consonanti?.[0] + consonanti?.[1] + consonanti?.[2];
		}

		if (cognome.length < 3) {
			cognome += 'x';
		} else {
			for (let i = 0; i <= cognome.length; i++) {
				if (cognome.charAt(i).match(/[aeiouAEIOU]/)) {
					vocaliCognome[i] = cognome.charAt(i).toUpperCase();
					vocali = vocaliCognome.filter(Boolean);
				} else if (cognome.charAt(i).match(/[\',\"\s]/)) {
					continue;
				} else {
					consonantiCognome[i] = cognome.charAt(i).toUpperCase();
					consonanti = consonantiCognome.filter(Boolean);

					if (consonantiCognome[i] !== '') nCons++;
				}
			}
		}

		if (consonanti.length < 3) {
			// prendo le consonanti a posizione 0,1 e la prima vocale
			consonantiPreseCognome = consonanti?.[0] + consonanti?.[1] + vocali?.[0];
		} else {
			// prendo le consonanti a posizione 0,1,2
			consonantiPreseCognome = consonanti?.[0] + consonanti?.[1] + consonanti?.[2];
		}

		// SE LE CONSONANTI SONO 1
		if (consonanti.length === 1) {
			consonantiPreseCognome = consonanti?.[0] + vocali?.[0] + vocali?.[1];
		}

		switch (mese.padStart(2, '0')) {
			case '01':
				mese = codiciMese[0];
				break;
			case '02':
				mese = codiciMese[1];
				break;
			case '03':
				mese = codiciMese[2];
				break;
			case '04':
				mese = codiciMese[3];
				break;
			case '05':
				mese = codiciMese[4];
				break;
			case '06':
				mese = codiciMese[5];
				break;
			case '07':
				mese = codiciMese[6];
				break;
			case '08':
				mese = codiciMese[7];
				break;
			case '09':
				mese = codiciMese[8];
				break;
			case '10':
				mese = codiciMese[9];
				break;
			case '11':
				mese = codiciMese[10];
				break;
			case '12':
				mese = codiciMese[11];
				break;
		}

		switch (giorno.padStart(2, '0')) {
			case '01':
			case '02':
			case '03':
			case '04':
			case '05':
			case '06':
			case '07':
			case '08':
			case '09':
			case '10':
			case '11':
			case '12':
			case '13':
			case '14':
			case '15':
			case '16':
			case '17':
			case '18':
			case '19':
			case '20':
			case '21':
			case '22':
			case '23':
			case '24':
			case '25':
			case '26':
			case '27':
			case '28':
			case '29':
			case '30':
			case '31':
				if (sesso === 'F') {
					giorno = +giorno.padStart(2, '0') + 40 + '';
				} else {
					giorno = giorno.padStart(2, '0');
				}

				break;
		}

		const CF = consonantiPreseCognome + consonantiPreseNome + anno + mese + giorno + city.belfiore;

		const CF_FX = this.getCF(CF);

		let sommaDispari: number = 0;
		let sommaPari: number = 0;
		let sommaControllo: number = 0;
		let letteraControllo: string = '';
		let valueToAdd: number = 0;

		for (let i = 0; i < CF_FX.length; i++) {
			valueToAdd = 0;
			if (i % 2 !== 1) {
				switch (CF_FX.charAt(i)) {
					case '0':
					case 'A':
						valueToAdd = 1;
						break;
					case '1':
					case 'B':
						valueToAdd = 0;
						break;
					case '2':
					case 'C':
						valueToAdd = 5;
						break;
					case '3':
					case 'D':
						valueToAdd = 7;
						break;
					case '4':
					case 'E':
						valueToAdd = 9;
						break;
					case '5':
					case 'F':
						valueToAdd = 13;
						break;
					case '6':
					case 'G':
						valueToAdd = 15;
						break;
					case '7':
					case 'H':
						valueToAdd = 17;
						break;
					case '8':
					case 'I':
						valueToAdd = 19;
						break;
					case '9':
					case 'J':
						valueToAdd = 21;
						break;
					case 'K':
						valueToAdd = 2;
						break;
					case 'L':
						valueToAdd = 4;
						break;
					case 'M':
						valueToAdd = 18;
						break;
					case 'N':
						valueToAdd = 20;
						break;
					case 'O':
						valueToAdd = 11;
						break;
					case 'P':
						valueToAdd = 3;
						break;
					case 'Q':
						valueToAdd = 6;
						break;
					case 'R':
						valueToAdd = 8;
						break;
					case 'S':
						valueToAdd = 12;
						break;
					case 'T':
						valueToAdd = 14;
						break;
					case 'U':
						valueToAdd = 16;
						break;
					case 'V':
						valueToAdd = 10;
						break;
					case 'W':
						valueToAdd = 22;
						break;
					case 'X':
						valueToAdd = 25;
						break;
					case 'Y':
						valueToAdd = 24;
						break;
					case 'Z':
						valueToAdd = 23;
						break;
				}
				sommaDispari += valueToAdd;
			} else {
				switch (CF_FX.charAt(i)) {
					case '0':
					case 'A':
						valueToAdd = 0;
						break;
					case '1':
					case 'B':
						valueToAdd = 1;
						break;
					case '2':
					case 'C':
						valueToAdd = 2;
						break;
					case '3':
					case 'D':
						valueToAdd = 3;
						break;
					case '4':
					case 'E':
						valueToAdd = 4;
						break;
					case '5':
					case 'F':
						valueToAdd = 5;
						break;
					case '6':
					case 'G':
						valueToAdd = 6;
						break;
					case '7':
					case 'H':
						valueToAdd = 7;
						break;
					case '8':
					case 'I':
						valueToAdd = 8;
						break;
					case '9':
					case 'J':
						valueToAdd = 9;
						break;
					case 'K':
						valueToAdd = 10;
						break;
					case 'L':
						valueToAdd = 11;
						break;
					case 'M':
						valueToAdd = 12;
						break;
					case 'N':
						valueToAdd = 13;
						break;
					case 'O':
						valueToAdd = 14;
						break;
					case 'P':
						valueToAdd = 15;
						break;
					case 'Q':
						valueToAdd = 16;
						break;
					case 'R':
						valueToAdd = 17;
						break;
					case 'S':
						valueToAdd = 18;
						break;
					case 'T':
						valueToAdd = 19;
						break;
					case 'U':
						valueToAdd = 20;
						break;
					case 'V':
						valueToAdd = 21;
						break;
					case 'W':
						valueToAdd = 22;
						break;
					case 'X':
						valueToAdd = 23;
						break;
					case 'Y':
						valueToAdd = 24;
						break;
					case 'Z':
						valueToAdd = 25;
						break;
				}
				sommaPari += valueToAdd;
			}
		}
		sommaControllo = (sommaDispari + sommaPari) % 26;

		let caratteri_lista = [
			'A',
			'B',
			'C',
			'D',
			'E',
			'F',
			'G',
			'H',
			'I',
			'J',
			'K',
			'L',
			'M',
			'N',
			'O',
			'P',
			'Q',
			'R',
			'S',
			'T',
			'U',
			'V',
			'W',
			'X',
			'Y',
			'Z'
		];
		for (let i = 0; i <= 26; i++) {
			switch (sommaControllo) {
				case i:
					letteraControllo = caratteri_lista[i];

					break;
			}
		}

		return CF_FX + letteraControllo;
	}

	private getCF(Cod) {
		let CF_MOD;
		let CF_MOD_ORA = '';
		for (let i = 0; i <= Cod.length; ++i) {
			CF_MOD = Cod.charAt(i);

			if (CF_MOD === ',') {
				continue;
			} else {
				CF_MOD_ORA += Cod.charAt(i);
			}
		}
		return CF_MOD_ORA;
	}
}
