import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { selectAuthLoginState } from '../../core/auth/auth.selectors';
import { LogService } from '../../core/log/log.service';
import { onlyWhenAuthenticated, onlyWhenOnline } from '../../shared/util/operators';
import { ErrorMessage } from '../error/error-message.model';
import { manageError } from '../error/error.util';
import { loadCategory, loadCategoryFailure, loadCategorySuccess } from './category.actions';
import { Category } from './category.model';
import { selectCategory } from './category.selectors';
import { CategoryService } from './category.service';

@Injectable({
	providedIn: 'root'
})
export class CategoryEffects {
	loadCategory$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadCategory),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap((action) => this.logService.info('Effect: loadCategory', action)),
			concatLatestFrom(() => this.store.select(selectCategory)),
			filter(([_, categories]) => categories.length === 0),
			switchMap(() =>
				this.categoryService.loadCategory().pipe(
					tap((datiCategory: Category[]) =>
						this.logService.infoDebug('Effect: loadCategory data received --> ', datiCategory)
					),
					map((datiCategory: Category[]) => loadCategorySuccess({ datiCategory })),
					catchError((error: ErrorMessage) => of(loadCategoryFailure({ error })))
				)
			)
		)
	);

	//  load failure
	loadCategoryFailure$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadCategoryFailure),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			tap((action) => this.logService.info('Effect: loadCategoryFailure ', action)),
			map(([{ error }, authLoginState]) => manageError(error, authLoginState))
		)
	);

	constructor(
		private actions$: Actions,
		private categoryService: CategoryService,
		private logService: LogService,
		private store: Store
	) {}
}
