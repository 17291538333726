import { ComponentType } from '@angular/cdk/portal';
import { MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { createAction, props } from '@ngrx/store';
import { NotificationDN } from './notification.model';

export const showSnackbar = createAction(
	'[Notification] showSnackbar',
	props<{ payload: NotificationDN }>()
);

export const showSnackbarFromComponent = createAction(
	'[Notification] showSnackbarFromComponent',
	props<{
		component: ComponentType<any>;
		payload: NotificationDN;
		verticalPosition: MatSnackBarVerticalPosition;
	}>()
);
