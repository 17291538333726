import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { selectAuthLoginState } from '../../core/auth/auth.selectors';
import { LogService } from '../../core/log/log.service';
import { onlyWhenAuthenticated, onlyWhenOnline } from '../../shared/util/operators';
import { ErrorMessage } from '../error/error-message.model';
import { manageError } from '../error/error.util';
import { loadCityByPrefix, loadCityByPrefixFailure, loadCityByPrefixSuccess } from './city.actions';
import { City } from './city.model';
import { CityService } from './city.service';

@Injectable({ providedIn: 'root' })
export class CityEffects {
	loadCityByPrefix$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadCityByPrefix),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap((action) => this.logService.info('Effect: loadCityByPrefix', action)),
			switchMap((action) =>
				this.cityService.loadCityByPrefix(action.prefix).pipe(
					tap((data) =>
						this.logService.infoDebug('Effect: loadCityByPrefix data received --> ', data)
					),
					map((cityData: City[]) => loadCityByPrefixSuccess({ cityData })),
					catchError((error: ErrorMessage) => of(loadCityByPrefixFailure({ error })))
				)
			)
		)
	);

	//  load failure
	loadFailures = createEffect(() =>
		this.actions$.pipe(
			ofType(loadCityByPrefixFailure),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			tap(() => this.logService.info('Effect: loadCityByPrefixFailure', '')),
			map(([{ error }, authLoginState]) => manageError(error, authLoginState))
		)
	);

	constructor(
		private actions$: Actions,
		private cityService: CityService,
		private store: Store,
		private logService: LogService
	) {}
}
