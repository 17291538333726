import { isPlatformServer } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID, inject } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/core';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { defer, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchAll, take } from 'rxjs/operators';
import { EnvironmentService } from '../../../../environments/environment.service';
import { ErrorMessage, ErrorType } from '../../../domain/error/error-message.model';
import { decodeError } from '../../../domain/error/error.util';
import { authRenewToken } from '../../auth/auth.actions';
import { AuthLoginState, HealthCheck, Token } from '../../auth/auth.models';
import { selectAuthLoginState, selectGetAccessToken } from '../../auth/auth.selectors';
import { selectAuthState } from '../../core.state';
import { HttpStatusCode } from '../../http-status/http-status.model';
import { LogService } from '../../log/log.service';
import { APIKEY } from '../../../../../tokens';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class CallService {
	constructor(
		private http: HttpClient,
		private environmentService: EnvironmentService,
		private store: Store,
		private logService: LogService,
		@Inject(PLATFORM_ID) private platformId: Object,
		@Optional() @Inject(APIKEY) private apiKey: string,

		private state: TransferState,
    private translate: TranslateService
	) {}

	// CallApi calls a backend Dottnet Api
	//  Da rivedere la questione del filter. Se uso loginState, ho problemi con le richieste
	public CallApi<T>(
		method: string,
		url: string,
		body?: any,
		params?: HttpParams,
		headers?: HttpHeaders
	): Observable<T> {
		// const apiKey = '34f8092b-5976-3333-860b-114727b29eaa';

		const urltoCall: string = this.environmentService.apiDomain + url;
		if (isPlatformServer(this.platformId)) {
			this.logService.info('CallApi, injecting APIKEY:', this.apiKey);
			if (headers === undefined) {
				headers = new HttpHeaders();
			}
			// headers = headers.append('API-KEY', this.apiKey);
			headers = headers.append('API-KEY', '');
		}
		return this.store.select(selectGetAccessToken).pipe(
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			filter(([_, authLoginState]) =>
				[AuthLoginState.LOGGEDHARD, AuthLoginState.LOGGEDSOFT, AuthLoginState.LOGGEDGUEST].includes(
					authLoginState
				)
			),
			take(1),
			mergeMap(([accessToken, _]) => {
				const dataKey = makeStateKey<T>(url);
				const savedData: T = this.state.get<T>(dataKey, null);
				if (isPlatformServer(this.platformId) || savedData === null) {
					// we're on the server, or on the client with savedValue not null
					return this.callInternal<T>(
						method,
						urltoCall,
						isPlatformServer(this.platformId)? undefined:accessToken.token,
						body,
						params,
						headers
					).pipe(
						map((dataToSave: T) => {
							if (isPlatformServer(this.platformId)) {
								this.logService.info('Setting cache key in state:', url);
								this.state.set<T>(dataKey, dataToSave);
							}
							return dataToSave;
						})
					);
					// return retVal;
				} else {
					// we're on the client AND savedValue is null
					this.logService.info('Found cache key in state:', url);
					return of<T>(savedData);
				}
			})
		);
	}

	// CallRenew renews the auth token
	public CallRenew<T>(
		method: string,
		url: string,
		renewToken: Token,
		body?: any,
		params?: HttpParams,
		headers?: HttpHeaders
	): Observable<T> {
		const urltoCall: string = this.environmentService.authDomain + url;

		return this.callInternal<T>(method, urltoCall, renewToken.token, body, params, headers);
		/* return this.store$.select(selectGetAccessToken).pipe(
      take(1),
      mergeMap(accessToken => this.callInternal<T> (method, urltoCall,  renewToken.token, body,params, headers))
    )
    */
	}

	// CallAuth calls a method in the auth server
	public CallAuth<T>(
		method: string,
		url: string,
		accessToken?: string,
		body?: any,
		params?: HttpParams,
		headers?: HttpHeaders
	): Observable<T> {
		const urltoCall: string = this.environmentService.authDomain + url;

		return this.callInternal<T>(method, urltoCall, accessToken, body, params, headers);
	}

	// Call calls a generic http method. Needs an absolute path
	public Call<T>(
		method: string,
		url: string,
		accessToken?: string,
		body?: any,
		params?: HttpParams,
		headers?: HttpHeaders
	): Observable<T> {
		return this.callInternal<T>(method, url, accessToken, body, params, headers);
	}

	// CallHealthCheck checks if backend is online
	public CallHealthCheck(): Observable<HealthCheck> {
		const urltoCall: string = this.environmentService.backendDomain + '/healthcheck';

		return this.callInternal<HealthCheck>('GET', urltoCall);
	}

	// Call calls a generic http method
	public callInternal<T>(
		method: string,
		url: string,
		token?: string,
		body?: any,
		params?: HttpParams,
		headers?: HttpHeaders
	): Observable<T> {
		this.logService.info('callInternal: ', 'url:' + url + ' method: ' + method); // + ' token: ' + token);
		const authHeader: string = 'Authorization';

		const options: {
			body?: any;
			headers?: HttpHeaders; //  | {[header: string]: string | string[]; };
			observe?: 'body';
			params?: HttpParams | { [param: string]: string | string[] };
			responseType?: 'json'; // 'arraybuffer' | 'blob' | 'json' | 'text';
			reportProgress?: boolean;
			withCredentials?: boolean;
		} = {
			observe: 'body',
			responseType: 'json',
			reportProgress: false,
			withCredentials: true
		};
		if (headers !== undefined) {
			this.logService.debug('callInternal, adding headers ', headers);
			options.headers = headers;
		}

		if (token !== undefined) {
			this.logService.debug('callInternal, adding access token ');

			if (options.headers === undefined) {
				options.headers = new HttpHeaders();
			}

			options.headers = options.headers.set(authHeader, 'Bearer ' + token);
		}

		if (body !== undefined) {
			options.body = body;
		}

		if (params !== undefined) {
			options.params = params;
		}

		//  Start by calling the service. If everything is right, we're happy and done.
		this.logService.debug('callInternal, calling request first time: ');

		const result$: Observable<T> = this.http.request<T>(method, url, options).pipe(
			//  catch the error
			catchError((error) => {
				//  If we cannot recover, we just forward the error to the caller.
				const errorMessage: ErrorMessage = decodeError(error, this.translate);
				if (error.status !== HttpStatusCode.Unauthorized) {
					this.logService.typedError(
						'callInternal, throwing non Unauthorized error: ',
						errorMessage
					);

					return throwError(() => errorMessage);
				}

				/*
				 * Otherwise, we need to
				 * 1) refresh the token
				 * 2) call the Call<T> (newToken) again
				 *
				 * We have a problem: dispatchAction() returns void, we only get to know when and whether
				 * the token has been refreshed after the store tells us so.
				 * However, we cannot call dispatchAction() directly, because we need to capture the side
				 * effects it produces.
				 */
				//  is the 401 recoverable?
				//  401 is not recoverable, login needed

				if (
					errorMessage.Code === ErrorType.ErrorRenewTokenScaduto ||
					errorMessage.Code === ErrorType.ErrorRenewTokenInvalidato
				) {
					this.logService.typedError('Renew token expired, throwing error: ', errorMessage);
					return throwError(() => errorMessage);
				}

				if (errorMessage.Code === ErrorType.ErrorApiKeyIncorrect) {
					this.logService.typedError(
						'Api-Key incorrect server side, throwing error: ',
						errorMessage
					);
					return throwError(() => errorMessage);
				}

				if (errorMessage.Code === ErrorType.ErrorIpNotAuthorized) {
					this.logService.typedError(
						'Ip not authorized server side, throwing error: ',
						errorMessage
					);
					return throwError(() => errorMessage);
				}
				if (errorMessage.Code === ErrorType.ErrorSoftTokenScaduto) {
					this.logService.typedError('SoftLogin token expired, throwing error: ', errorMessage);
					return throwError(() => errorMessage);
				}
				if (
					// errorMessage.Code === ErrorType.ErrorSessioneScaduta ||
					errorMessage.Code === ErrorType.ErrorCookieSessioneNonPresente
				) {
					this.logService.typedError('Session expired, throwing error: ', errorMessage);
					return throwError(() => errorMessage);
				}
				if (errorMessage.Code === ErrorType.ErrorUtenteNonAutorizzato) {
					this.logService.typedError('Wrong credentials, throwing error: ', errorMessage);
					return throwError(() => errorMessage);
				}
				this.logService.typedError('CallInternal, Caught unauthorized error: ', errorMessage);

				/*
				 * Solution => use defer() (https://rxjs-dev.firebaseapp.com/api/index/function/defer)
				 * Essentialy, defer() calls an observable factory (i.e. a function that returns
				 * an observable) when it gets subscribed to.
				 * Here, we create an observable that, when subscribed to, dispatches the refresh action
				 * and emits the refreshed token when the store notifies a change.
				 */

				/**
				 * An Observable that dispatches that forces the dispatch of the token and emits the
				 * refreshed token as soon as it's ready.
				 */
				/* const refreshToken$ = defer(() => {
          this.store$.dispatch(authRenewToken({UrlTo:''}));
          return this.store$.select(selectAuthState).pipe(
              filter(authState => authState.authLoginState == AuthLoginState.LOGGEDHARD ),
              map((authState) => authState.accessToken));

          // dispatchRefresh();
          // return this.store$.pipe(filter((v) => v.token !== undefined), map((v) => v.token));
        });
*/
				/*
				 * We now have a way to refresh the token and be notified of this action happening.
				 * What is left to be done is to take the refreshed token (emitted by refreshToken$), pass
				 * it into Call<T>, and then SWITCH the outer subscription to the inner, newly created Call<T> observable.
				 */

				/**
				 * Our final observable listens to the refresh of the token, maps the token into Call and then forgets
				 * the subscription to refreshToken, only keeping the subscription to the inner, newly created, Call<T>.
				 */
				const obs = this.getNewAccessToken().pipe((refresh$) =>
					refresh$.pipe(
						map((newAccessToken) => {
							this.logService.debug(
								'callInternal, new access token received: ',
								newAccessToken.token
							);

							/* let newHeaders : HttpHeaders = new HttpHeaders();
              if (headers){
                 newHeaders = headers;
              }
              newHeaders = newHeaders.set(authHeader, 'Bearer ' + newAccessToken.token);
              */
							this.logService.debug('callInternal, calling request second time: ');
							return this.callInternal<T>(method, url, newAccessToken.token, body, params, headers);
						}),
						switchAll() // Without this, we'd have an Observable<Observable<T>>, we need Observable<T>!
					)
				);
				return obs;
			}) // catcherror
		); // pipe di request
		return result$;
	}

	getNewAccessToken(): Observable<Token> {
		const refreshToken$: Observable<Token> = defer(() => {
			this.logService.debug('callInternal, dispatching authRenewToken ');
			this.store.dispatch(authRenewToken());
			return this.store.select(selectAuthState).pipe(
				filter(
					(authState) =>
						authState.authLoginState === AuthLoginState.LOGGEDHARD ||
						authState.authLoginState === AuthLoginState.LOGGEDSOFT
				),
				// eslint-disable-next-line ngrx/avoid-mapping-selectors
				map((authState) => authState.accessToken)
			);
		});

		return refreshToken$;
	}

	downloadFile(url: string) {
		return this.http.get<Blob>(url, { responseType: 'blob' as 'json' });
	}

	getPdfFromUrl(url: string): Observable<Blob> {
		const headers = this.getHttpHeadersForPdfDownload();
		return this.http.get<Blob>(url, { headers: headers, responseType: 'blob' as 'json' });
	}

	private getHttpHeadersForPdfDownload(): HttpHeaders {
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/pdf');
		return headers;
	}
}
