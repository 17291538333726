import { createReducer, on } from '@ngrx/store';
import {
	loadAifaFailure,
	loadAifaSuccess,
	loadFocusListFailure,
	loadFocusListSuccess,
	loadFocusMainFailure,
	loadFocusMainSuccess,
	loadFocusVideoListFailure,
	loadFocusVideoListSuccess,
	loadFocusVideoMainFailure,
	loadFocusVideoMainSuccess,
	loadInsightsFailure,
	loadInsightsSuccess,
	loadInstitutionsFailure,
	loadInstitutionsSuccess,
	loadInterestingFailure,
	loadInterestingSuccess,
	loadLatestNewsFailure,
	loadLatestNewsSuccess,
	loadLatestVideosSuccess,
	loadMainHighlightFailure,
	loadMainHighlightSuccess,
	loadMedlexFailure,
	loadMedlexSuccess,
	loadMinisterialBulletinFailure,
	loadMinisterialBulletinSuccess,
	loadMostReadFailure,
	loadMostReadSuccess,
	loadRelatedFailure,
	loadRelatedSuccess
} from './widget.actions';
import { DNContentState, DNFocusContentState, WidgetState } from './widget.model';

export const widgetsInitialState: WidgetState = {
	medlexContent: <DNContentState>{},
	bulletinContent: <DNContentState>{},
	aifaContent: <DNContentState>{},
	institutionsContent: <DNContentState>{},
	highlightedContent: <DNContentState>{},
	mostReadContent: <DNContentState>{},
	latestNewsContent: <DNContentState>{},
	latestVideosContent: <DNContentState>{},
	focusArticleContent: <DNFocusContentState>{},
	focusVideoContent: <DNFocusContentState>{},
	insightsContent: <DNContentState>{},
	relatedContent: <DNContentState>{},
	interestingContent: <DNContentState>{}
};

export const widgetReducer = createReducer(
	widgetsInitialState,

	on(loadMedlexSuccess, (state, { medlexData }) => ({
		...state,
		medlexContent: { content: medlexData, error: undefined }
	})),
	on(loadMedlexFailure, (state, { error }) => ({
		...state,
		medlexContent: { content: undefined, error: error }
	})),
	on(loadMinisterialBulletinSuccess, (state, { ministerialBulletinData }) => ({
		...state,
		bulletinContent: { content: ministerialBulletinData, error: undefined }
	})),
	on(loadMinisterialBulletinFailure, (state, { error }) => ({
		...state,
		bulletinContent: { content: undefined, error: error }
	})),
	on(loadAifaSuccess, (state, { aifaData }) => ({
		...state,
		aifaContent: { content: aifaData, error: undefined }
	})),
	on(loadAifaFailure, (state, { error }) => ({
		...state,
		aifaContent: { content: undefined, error: error }
	})),
	on(loadInstitutionsSuccess, (state, { institutionsData }) => ({
		...state,
		institutionsContent: { content: institutionsData, error: undefined }
	})),
	on(loadInstitutionsFailure, (state, { error }) => ({
		...state,
		institutionsContent: { content: undefined, error: error }
	})),
	on(loadMainHighlightSuccess, (state, { highlightData }) => ({
		...state,
		highlightedContent: { content: [highlightData], error: undefined }
	})),
	on(loadMainHighlightFailure, (state, { error }) => ({
		...state,
		highlightedContent: { content: undefined, error: error }
	})),
	on(loadMostReadSuccess, (state, { mostReadData }) => ({
		...state,
		mostReadContent: { content: mostReadData, error: undefined }
	})),
	on(loadMostReadFailure, (state, { error }) => ({
		...state,
		mostReadContent: { content: undefined, error: error }
	})),
	on(loadLatestNewsSuccess, (state, { latestNewsData }) => ({
		...state,
		latestNewsContent: { content: latestNewsData, error: undefined }
	})),
	on(loadLatestNewsFailure, (state, { error }) => ({
		...state,
		latestNewsContent: { content: undefined, error: error }
	})),
	on(loadRelatedSuccess, (state, { relatedData }) => ({
		...state,
		relatedContent: { content: relatedData, error: undefined }
	})),
	on(loadRelatedFailure, (state, { error }) => ({
		...state,
		relatedContent: { content: undefined, error: error }
	})),
	on(loadInterestingSuccess, (state, { interestingData }) => ({
		...state,
		interestingContent: { content: interestingData, error: undefined }
	})),
	on(loadInterestingFailure, (state, { error }) => ({
		...state,
		interestingContent: { content: undefined, error: error }
	})),
	on(loadLatestVideosSuccess, (state, { latestVideosData }) => ({
		...state,
		latestVideosContent: { content: latestVideosData, error: undefined }
	})),
	on(loadMostReadFailure, (state, { error }) => ({
		...state,
		latestVideosContent: { content: undefined, error: error }
	})),
	on(loadFocusMainSuccess, (state, { focusMainData }) => ({
		...state,
		focusArticleContent: {
			...state.focusArticleContent,
			mainContent: focusMainData,
			error: undefined
		}
	})),
	on(loadFocusListSuccess, (state, { focusListData }) => ({
		...state,
		focusArticleContent: {
			...state.focusArticleContent,
			listContent: focusListData,
			error: undefined
		}
	})),
	on(loadFocusMainFailure, loadFocusListFailure, (state, { error }) => ({
		...state,
		focusArticleContent: {
			...state.focusArticleContent,
			mainContent: undefined,
			listContent: undefined,
			error: error
		}
	})),
	on(loadFocusVideoMainSuccess, (state, { focusVideoMainData }) => ({
		...state,
		focusVideoContent: {
			...state.focusVideoContent,
			mainContent: focusVideoMainData,
			error: undefined
		}
	})),
	on(loadFocusVideoListSuccess, (state, { focusVideoListData }) => ({
		...state,
		focusVideoContent: {
			...state.focusVideoContent,
			listContent: focusVideoListData,
			error: undefined
		}
	})),
	on(loadFocusVideoMainFailure, loadFocusVideoListFailure, (state, { error }) => ({
		...state,
		focusVideoContent: {
			...state.focusVideoContent,
			mainContent: undefined,
			listContent: undefined,
			error: error
		}
	})),
	on(loadInsightsSuccess, (state, { insightsData }) => ({
		...state,
		insightsContent: { content: insightsData, error: undefined }
	})),
	on(loadInsightsFailure, (state, { error }) => ({
		...state,
		insightsContent: { content: undefined, error: error }
	}))
);
