import { Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';

export const navigateHome = createAction('[Router] navigateHome');

export const navigateSignin = createAction('[Router] navigateSignin');

export const navigateOffline = createAction(
	'[Router] navigateOffline',
	props<{ currentUrl: string }>()
);

export const navigateToContext = createAction('[Router] NavigateToContext');

export const navigateToPage = createAction(
	'[Router] navigateToPage',
	props<{ page: string; queryParams?: Params }>()
);

export const serverPolling = createAction('[Router] serverPolling');

export const redirectWhenServerUp = createAction('[Router] redirectWhenServerUp');

export const reloadPage = createAction(
	'[Router] ReloadPage',
	props<{ returnTo?: string | 'self' | null }>()
);
