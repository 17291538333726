import { isPlatformBrowser, NgIf, NgFor, TitleCasePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	PLATFORM_ID
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, Observable, Subscription, tap } from 'rxjs';
import { authLogout } from '../../core/auth/auth.actions';
import { AuthLoginState } from '../../core/auth/auth.models';
import { selectGetLoginTypeString } from '../../core/auth/auth.selectors';
import { selectSessionID } from '../../core/session/session.selectors';
import { clearList } from '../../domain/content/content.actions';
import { UserDTO } from '../../domain/user/user.model';
import { selectUserId } from '../../domain/user/user.selectors';
import { openDialog } from '../dialog/dn-dialog.actions';
import { logo, navigation, navigationSideMenu } from '../navigation/navigation.model';
import { SigninDialogComponent } from './../dialog/signin-dialog/signin-dialog.component';
import { selectCurrentContent } from '../../domain/content/content.selectors';
import { Topic } from '../../domain/topic/topic.model';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

interface MenuItem {
	label: string;
	action: Function;
	routerLink: string;
	logoutIcon?: boolean;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dottnet-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    standalone: true,
    imports: [MatToolbarModule, MatButtonModule, MatIconModule, RouterLink, NgIf, MatFormFieldModule, MatInputModule, FormsModule, MatMenuModule, NgFor, TitleCasePipe, TranslateModule]
})
export class ToolbarComponent implements OnInit, OnDestroy {
	NOT_LOGGED_MENU_ITEMS: MenuItem[] = [
		{
			label: 'dottnet.menu.login',
			action: this.openSignin,
			routerLink: undefined
		},
		{
			label: 'dottnet.menu.signup',
			action: undefined,
			routerLink: '/signup'
		},
		{
			label: 'dottnet.menu.managelog',
			action: undefined,
			routerLink: '/log'
		}
	];

	LOGGED_MENU_ITEMS: MenuItem[] = [
		{
			label: 'dottnet.menu.profile',
			action: this.openSignin,
			routerLink: 'profile/edit'
		},
		{
			label: 'dottnet.menu.managelog',
			action: undefined,
			routerLink: '/log'
		},
		{
			label: 'dottnet.menu.logout',
			action: this.onLogoutClick,
			routerLink: undefined,
			logoutIcon: true
		}
	];

	// Input variables
	// Reference to the outer sidenav template reference
	@Input() sidenav: MatSidenav;
	// Reference to the search field
	@Input() searchText: string;
	// Values from app observables
	@Input() authLoginState: AuthLoginState;
	@Input() user: UserDTO;
	@Input() topic: Topic;

	// References for enums
	readonly AuthLoginState = AuthLoginState;

	// Constants
	navigation: any;
	navigationSideMenu: any;
	logo: string;

	userMenuItems: MenuItem[] = [];

	// Observables
	sessionID$: Observable<number>;
	loginType$: Observable<string>;
	// Instance vars
	isBrowser: boolean = false;
	// Subs
	userSub: Subscription;
	contentSub: Subscription;

	constructor(
		private store: Store,
		@Inject(PLATFORM_ID) private platformId: Object,
		private route: Router,
		private changeDetector: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.navigation = navigation;
		this.navigationSideMenu = navigationSideMenu;
		this.logo = logo;

		this.isBrowser = isPlatformBrowser(this.platformId);

		this.sessionID$ = this.store.select(selectSessionID);
		this.loginType$ = this.store.select(selectGetLoginTypeString);

		this.userSub = this.store
			.select(selectUserId)
			.pipe(filter((userId) => !!userId))
			.subscribe(
				(userId) =>
					(this.userMenuItems = userId === -1 ? this.NOT_LOGGED_MENU_ITEMS : this.LOGGED_MENU_ITEMS)
			);
	}

	ngOnDestroy(): void {
		if (this.userSub) this.userSub.unsubscribe();
		if (this.contentSub) this.contentSub.unsubscribe();
	}

	openSignin() {
		this.store.dispatch(
			openDialog({
				content: undefined,
				urlToDownload: undefined,
				urlWithContext: undefined,
				componentType: SigninDialogComponent,
				panelClass: 'dn-dialog-small'
			})
		);
	}

	doAction(item: MenuItem) {
		if (item.action) {
			item.action.apply(this);
		}
	}

	onLogoutClick() {
		this.store.dispatch(authLogout());
	}

	searchTextResult() {
		if (this.checkMinLength()) {
			this.route.navigate(['/ricerca', this.searchText]);
		}
	}

	checkMinLength(): boolean {
		return this.searchText?.length >= 3;
	}

	getUserName(): string {
		if (!(this.user?.nome + this.user?.cognome) || this.user?.nome === 'Guest')
			return 'Accedi a DottNet';
		return `${this.user?.nome} ${this.user?.cognome}`;
	}
}
