import {
	APP_INITIALIZER,
	PLATFORM_ID,
	importProvidersFrom,
	Injectable,
	ErrorHandler,
	Injector,
	isDevMode,
	ApplicationConfig,
	TransferState,
	Optional,
	InjectionToken
} from '@angular/core';

import { environment } from './environments/environment';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
	InMemoryScrollingOptions,
	NoPreloading,
	ParamMap,
	provideRouter,
	withEnabledBlockingInitialNavigation,
	withInMemoryScrolling,
	withPreloading
} from '@angular/router';
import { Token, LoginType, AuthLoginState } from './app/core/auth/auth.models';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { loadTopic } from './app/domain/topic/topic.actions';
import { loadTemplateCt } from './app/domain/template-ct/template-ct.actions';
import { replaceAll, getQueryParametersFromURL } from './app/shared/util/util';
import { Observable, merge, of, tap, take } from 'rxjs';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from './environments/environment.service';
import { LogService } from './app/core/log/log.service';
import { LocalStorageService } from './app/core/local-storage/local-storage.service';
import { META_REDUCERS, Store, provideStore } from '@ngrx/store';
// import { CoreModule } from './app/core/core.module';
import {
	HAMMER_GESTURE_CONFIG,
	HammerGestureConfig,
	HammerModule,
	Meta,
	Title
} from '@angular/platform-browser';
import { APP_ROUTES } from './app/app.routes';
import {
	RouterStateSerializer,
	StoreRouterConnectingModule,
	provideRouterStore
} from '@ngrx/router-store';
import { reducers } from './app/core/core.state';
import { provideEffects } from '@ngrx/effects';
import { UserEffects } from './app/domain/user/user.effects';
import { WidgetEffects } from './app/domain/widget/widget.effects';
import { DialogEffects } from './app/shared/dialog/dn-dialog.effects';
import { CookieConsentEffects } from './app/core/cookie-consent/cookie-consent.effects';
import { AuthEffects } from './app/core/auth/auth.effects';
import { ClickEffects } from './app/core/clicks/clicks.effects';
import { ErrorEffects } from './app/core/error-handler/error.effects';
import { RoutesEffects } from './app/core/router/routes.effects';
import { SessionEffects } from './app/core/session/session.effects';
import { TopicEffects } from './app/domain/topic/topic.effects';
import { CookieService } from './app/core/cookie-consent/services/cookie/cookie.service';
import {
	//	HTTP_INTERCEPTORS,
	HttpClient,
	//	HttpClientModule,
	provideHttpClient,
	withFetch
	//	withInterceptorsFromDi,
	//    withRequestsMadeViaParent
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DottnetErrorHandler } from './app/core/error-handler/error-handler';
import { MetaService } from './app/core/meta/meta.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CustomSerializer } from './app/core/router/custom-serializer';
import { metaReducerFactory } from './app/core/meta-reducers/debug.reducer';
// import { CookieInterceptor } from './app/core/interceptors/cookie.interceptor';
import { StoreDevtoolsModule, provideStoreDevtools } from '@ngrx/store-devtools';
import { CityEffects } from './app/domain/city/city.effects';
import { CategoryEffects } from './app/domain/category/category.effects';
import { SpecialtyEffects } from './app/domain/specialty/specialty.effects';
import { ProvinceEffects } from './app/domain/province/province.effects';
import { UserFormEffects } from './app/shared/user-form/user-form.effects';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationEffects } from './app/core/notifications/notification.effects';
import { initDottnet } from './app/core/init/init-dottnet';
import { CallService } from './app/core/call-service/browser/call.service';
import { APIKEY } from '../tokens';
import { ProfessionEffects } from './app/domain/profession/profession.effects';
import { InitEffects } from './app/core/init/init.effects';

const scrollConfig: InMemoryScrollingOptions = {
	scrollPositionRestoration: 'enabled',
	anchorScrolling: 'enabled'
};

// making hammer config (3)
@Injectable({ providedIn: 'root' })
class MyHammerConfig extends HammerGestureConfig {
	overrides = <any>{
		pinch: { enable: true }
	};
}


export function errorHandlerFactory(injector: Injector) {
	return new DottnetErrorHandler(injector);
}

export function metaServiceFactory(
	title: Title,
	meta: Meta,
	environmentService: EnvironmentService,
	document: Document
) {
	return new MetaService(title, meta, environmentService, document);
}


export function httpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, `${environment.i18nPrefix}/assets/i18n/`, '.json');
}

export const appConfig: ApplicationConfig = {
	providers: [
		CookieService,
		provideHttpClient(withFetch()), // , withInterceptorsFromDi()),
		provideRouter(
			APP_ROUTES,
			withPreloading(NoPreloading),
			withInMemoryScrolling(scrollConfig),
			withEnabledBlockingInitialNavigation()
			// ?	scrollPositionRestoration: 'enabled',
		),
		provideStore(reducers, {
			// Added to prevent error when opening generic matDialog with ComponentType<ANY> type
			runtimeChecks: {
				strictActionImmutability: false
			}
		}),
		provideEffects(
			AuthEffects,
			UserEffects,
			WidgetEffects,
			//			GoogleAnalyticsEffects,
			ClickEffects,
			SessionEffects,
			DialogEffects,
			RoutesEffects,
			ErrorEffects,
			CookieConsentEffects,
			TopicEffects,
			CityEffects,
			CategoryEffects,
			SpecialtyEffects,
      ProfessionEffects,
			ProvinceEffects,
			UserFormEffects,
			NotificationEffects,
      InitEffects
		),
		provideRouterStore(),
		provideStoreDevtools({
			name: 'Dottnet',
			maxAge: 25, // Retains last 25 states
			logOnly: !isDevMode(), // Restrict extension to log-only mode
			autoPause: true, // Pauses recording actions and state changes when the extension window is not open
			trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
			traceLimit: 75 // maximum stack trace frames to be stored (in case trace option was provided as true)
		}),
		provideAnimations(),
		importProvidersFrom(
			MatDialogModule,
			MatSidenavModule,
			ReactiveFormsModule,
			MatSnackBarModule,
			HammerModule,
			StoreRouterConnectingModule.forRoot(),
			/* environment.production
            ? []
            : StoreDevtoolsModule.instrument({
                name: 'Dottnet'
            }),
            */
			TranslateModule.forRoot({
				loader: {
					provide: TranslateLoader,
					useFactory: httpLoaderFactory,
					deps: [HttpClient]
				}
			})

			// app
			// AppRoutingModule
		),
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: MyHammerConfig
		},
		{
			provide: APP_INITIALIZER,
			useFactory: initDottnet,
			deps: [
				PLATFORM_ID,
				Store,
				LocalStorageService,
				LogService,
				EnvironmentService,
				TranslateService
			],
			multi: true
		},

		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
		{ provide: ErrorHandler, deps: [Injector], useFactory: errorHandlerFactory },
		{ provide: RouterStateSerializer, useClass: CustomSerializer },
		{
			provide: META_REDUCERS,
			deps: [LogService],
			useFactory: metaReducerFactory,
			multi: true
		},
		// { provide: HTTP_INTERCEPTORS, useClass: CookieInterceptor, multi: true },
		{
			provide: MetaService,
			useFactory: metaServiceFactory,
			deps: [Title, Meta, EnvironmentService, DOCUMENT]
		}
	]
};
