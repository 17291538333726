import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { ErrorMessage } from '../error/error-message.model';
import { City } from './city.model';

export const cityAdapter: EntityAdapter<City> = createEntityAdapter<City>({
	selectId: (city) => city.idComune
});
export interface CityState extends EntityState<City> {
	error: ErrorMessage;
}
export const cityInitialState: CityState = cityAdapter.getInitialState({
	error: undefined
});
export const citySelectors = cityAdapter.getSelectors();
