import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../../domain/error/error-message.model';

export const dnLivreaConfig = createAction('[Init] dnLivreaConfig');


export const dnLivreaConfigSuccess = createAction(
	'[Init] dnLivreaConfigSuccess',
	props<{ livreaActive: boolean}>()
);
export const dnLivreaConfigFailure = createAction(
	'[Init] dnLivreaConfigFailure',
	props<{ error: ErrorMessage }>()
);
