import { createSelector } from '@ngrx/store';
import { selectTemplateCtState } from '../../core/core.state';
import { TemplateCt } from './template-ct.model';

/* export const selectTemplateByID = (id: number) =>
	createSelector(selectTemplateCtState, fromTemplateCt.getTemplateById(id));
*/
export const selectTemplateAll = () =>
	createSelector(selectTemplateCtState, (state) => state.templateCtMap);

export const selectTemplateByKey = (key: string) =>
	createSelector(selectTemplateCtState, (state) => state.templateCtMap[key]);

export const selectTemplateByID = (id: number) =>
	createSelector(selectTemplateCtState, (state) => {
		for (const templateName of Object.keys(state.templateCtMap)) {
			if (state.templateCtMap[templateName].templateID === id) {
				return state.templateCtMap[templateName];
			}
		}

		return undefined;
	});

export const selectContentTemplate = createSelector(selectTemplateCtState, (state) => {
	const result: TemplateCt[] = [];
	for (const templateName of Object.keys(state.templateCtMap)) {
		if (state.templateCtMap[templateName].isContent) {
			result.push(state.templateCtMap[templateName]);
		}
	}

	return result;
});
