<!-- eslint-disable @angular-eslint/template/use-track-by-function  -->
<div class="branding flex-row flex-justify-space-between flex-align-center">
    <div class="flex-row flex-justify-space-between flex-align-center">
        <a (click)="sidenav.close()" routerLink="home">
            <img [src]="homeLogo" [alt]="homeLogo" id="sidenavLogo" />
        </a>
        <ng-container *ngIf="
				authLoginState === AuthLoginState.LOGGEDHARD ||
				authLoginState === AuthLoginState.LOGGEDSOFT ||
				authLoginState === AuthLoginState.REFRESHING
			">
            <a class="username" (click)="sidenav.close()" routerLink="profile/edit" *ngIf="user">
				{{ user?.nome }} {{ user?.cognome }}
			</a>
        </ng-container>
        <a class="username" *ngIf="
				authLoginState === AuthLoginState.NOTLOGGED || authLoginState === AuthLoginState.LOGGEDGUEST
			" (click)="openSignin()">
			{{ 'dottnet.menu.login' | translate }}
		</a>
    </div>
    <button mat-icon-button (click)="sidenav.close()">
		<mat-icon>close</mat-icon>
	</button>
</div>
<hr class="divisorio-menu" />
<div class="search flex-row">
    <mat-form-field class="flex-fill">
        <mat-label>{{ 'dottnet.menu.ricerca' | translate }} </mat-label>
        <input matInput type="text" minlength="3" [(ngModel)]="searchText" (keydown.enter)="searchTextResult()" placeholder="{{ 'dottnet.search.toofewcharacters' | translate }}"/>
        <button matSuffix mat-icon-button aria-label="Search" (disabled)="!checkMinLength()"  (click)="searchTextResult()">
			<mat-icon>search</mat-icon>
		</button>
        <mat-error>{{ 'dottnet.search.toofewcharacters' | translate }}</mat-error>
    </mat-form-field>
</div>

<mat-nav-list>
    <ng-container *ngFor="let item of navigation; let i = index">
        <ng-container *ngIf="item.subLinks">
            <hr *ngIf="i > 0" class="divisorio-menu" />
            <a mat-list-item (click)="sidenav.close()" [routerLink]="[item.link]" routerLinkActive="active" class="rootLink">
				{{ item.label | translate }}
			</a>
            <hr class="divisorio-menu" />

            <ng-container *ngIf="user?user.premium:false && item.link === 'extra'">
                <ng-container *ngFor="let subItem of item.subLinksLogged; let i = index">
                    <hr *ngIf="i > 0" class="divisorio-menu" />
                    <a mat-list-item (click)="sidenav.close()" [routerLink]="[subItem.link]" routerLinkActive="active" class="subLink">
						{{ subItem.label | translate }}
					</a>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="user?user.premium:false && item.link === 'extra'">
                <ng-container *ngFor="let subItem of item.subLinks; let i = index">
                    <hr *ngIf="i > 0" class="divisorio-menu" />
                    <a mat-list-item (click)="sidenav.close()" [routerLink]="[subItem.link]" routerLinkActive="active" class="subLink">
						{{ subItem.label | translate }}
					</a>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="item.link !== 'extra'">
                <ng-container *ngFor="let subItem of item.subLinks; let i = index">
                    <hr *ngIf="i > 0" class="divisorio-menu" />
                    <a mat-list-item (click)="sidenav.close()" [routerLink]="[subItem.link]" routerLinkActive="active" class="subLink">
						{{ subItem.label | translate }}
					</a>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="!item.subLinks">
            <hr class="divisorio-menu" />
            <a mat-list-item (click)="sidenav.close()" [routerLink]="[item.link]" routerLinkActive="active" class="rootLink">
				{{ item.label | translate }}
			</a>
        </ng-container>
    </ng-container>

    <!-- <a
		mat-list-item
		*ngFor="let item of navigationSideMenu"
		(click)="sidenav.close()"
		[routerLink]="[item.link]"
		routerLinkActive="active"
	>
		{{ item.label | translate }}
	</a> -->
</mat-nav-list>
<div class="bottomNav flex-row" *ngIf="
		authLoginState === AuthLoginState.LOGGEDHARD ||
		authLoginState === AuthLoginState.LOGGEDSOFT ||
		authLoginState === AuthLoginState.REFRESHING
	">
    <button mat-flat-button color="accent" (click)="onLogoutClick(); sidenav.close()" class="flex-fill">
		{{ 'dottnet.menu.logout' | translate }}
	</button>
</div>
