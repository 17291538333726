import { createAction, props } from '@ngrx/store';
import { CookieConsentState } from './cookie-consent.model';

export const setCookieConsent = createAction(
	'[CookieConsent] setCookieConsent',
	props<{
		cookieConsent: CookieConsentState;
		// functional_google_analytics: boolean;
		// marketing_google_analytics: boolean;
		// marketing_meta: boolean;
		// status: boolean;
	}>()
);

export const setCookieConsentSuccess = createAction('[CookieConsent] setCookieConsentSuccess');
