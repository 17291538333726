import { loadProfession, loadProfessionSuccess, loadProfessionFailure } from './profession.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { ProfessionState, professionAdapter, professionInitialState } from './profession.entity';

export const reducer = createReducer(
	professionInitialState,
	on(loadProfession, (state) => ({ ...state, error: undefined })),
	on(loadProfessionSuccess, (state, action) => professionAdapter.setAll(action.datiProfession, state)),
	on(loadProfessionFailure, (state, action) => ({ ...state, error: action.error }))
);

export function professionReducer(
	state: ProfessionState | undefined,
	action: Action
): ProfessionState {
	return reducer(state, action);
}
