import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorMessage } from '../error/error-message.model';
import { ProfessionType } from './profession.model';

export const professionAdapter: EntityAdapter<ProfessionType> = createEntityAdapter<ProfessionType>({
	selectId: (profession) => profession.idTipoProfessione
});
export interface ProfessionState extends EntityState<ProfessionType> {
	error: ErrorMessage;
}
export const professionInitialState: ProfessionState = professionAdapter.getInitialState({
	error: undefined
});
export const professionSelectors = professionAdapter.getSelectors();
