import { ErrorMessage } from '../../domain/error/error-message.model';

export enum LoginType {
	HARDLOGIN = 0,
	SOFTLOGIN = 1,
	GUESTLOGIN = -1,
	NOLOGIN = -2
}

//  export type LoginType = 'interactive' | 'guest';

/*
export interface Utente {
	Username:             string;
	IdOperatore:          number;
	IdAnagrafica:         number;
	IDAer: 			      number;
	DescrizioneOperatore: string;
	UnitaOrganizzativa:   string;
	Email:                string;
	NomeUtente:           string;
	Tasks:               Task[] ;
}
*/
export enum AuthLoginState {
	NOTLOGGED = 'NOTLOGGED',
	LOGGEDGUEST = 'LOGGEDGUEST',
	LOGGEDSOFT = 'LOGGEDSOFT',
	LOGGEDHARD = 'LOGGEDHARD',
	REFRESHING = 'REFRESHING'
}
export interface Token {
	token: string;
	expires: number;
}
export interface ServerAuthData {
	AccessToken: Token;
	RenewToken: Token;
	IDAnagrafica: number;
	loginType: LoginType;
}

export interface AuthState {
	authLoginState: AuthLoginState;
	accessToken: Token;
	renewToken: Token;
	idAnagrafica: number;
	authProgress: AuthProgress;
	loginType: LoginType;
	backendState: BackendState;
}


export const enum LoadingState {
	INIT = 'INIT',
	LOADING = 'LOADING',
	LOADED = 'LOADED',
	ERROR = 'ERROR'
}

export const enum Backend {
	ONLINE = 'ONLINE',
	OFFLINE = 'OFFLINE'
}

export interface HealthCheck {
	status: string;
	errors: Map<string, string>;
}

export type AuthProgress = LoadingState | ErrorMessage;

export type BackendState = Backend | ErrorMessage;
