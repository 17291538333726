import { SafeHtml } from '@angular/platform-browser';
import { EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormGroup,FormControl } from '@angular/forms';
import { Content } from '../../../../domain/content/content.model';
import {
  ExternalClientInfo,
	isPageElement,
	PageElement
} from '../../../../domain/dynamic-container/dynamic-container.model';
import { UserDTO } from '../../../../domain/user/user.model';
import {
  CompletePaymentIntent,
	CouponState,
	MyPremiumModuleElement,
	MyPremiumState,
	PaymentConfirmation,
	PremiumDiscountType,
	PremiumModuleElement,
	PremiumSubscription,
	PremiumTokenError
} from '../../../../domain/extra/extra.model';
import { replaceAll } from '../../../../shared/util/util';
import { UserFormEcmInfos } from '../../../../shared/user-form/user-form.model';

const STARTING_DELIMITER: string = '{{';
const CLOSING_DELIMITER: string = '}}';

const COLLECTION_INJECTION_LABEL: string = 'dati';

export enum NewWidgetsTemplateId {
	NEW_COLLECTION = 174,
	NEW_CAROUSEL = 175,
	MENU = 164,
	NEW_IFRAME = 165,
	FOLLOW = 166,
	NEW_HTML = 167,
	ZOOM = 168,
	PAYMENT_FORM = 171,
	REP_BLOG = 161,
  FAD_ENROLLMENT = 179
}

export const isNewWidgetValid = (templateId: number) =>
	Object.values(NewWidgetsTemplateId).includes(templateId);

export interface ParametersToken {
	id_anagrafica?: number;
}

export interface resizeEmitterStruct {
	minHeight: number;
	owner: string;
}

export interface PaymentFormWidget {
	premiumModules$: Observable<PremiumModuleElement[]>;
  paymentIntent$: Observable<CompletePaymentIntent>;
  paymentSuccess$: Observable<boolean>;
  sessionId$: Observable<number>;
	discountTypeList$: Observable<PremiumDiscountType[]>;
	tokenError$: Observable<PremiumTokenError>;
	coupon$: Observable<CouponState>;
	myPremium$: Observable<MyPremiumState>;
	submitUpdateSubscription?: EventEmitter<{module: MyPremiumModuleElement, stripeId: string, userId: number}>;
	submitCheckTokenForm?: EventEmitter<string>;
	submitSubscription?: EventEmitter<string>;
	submitLoadUserPremium?: EventEmitter<number>;
  submitIntentSubscription?: EventEmitter<PremiumSubscription>;
  submitConfirmCardPayment?: EventEmitter<PaymentConfirmation>;
	submitCouponGeneration?: EventEmitter<{
		idTipoSconto: number;
		idAnagrafica: number;
	}>;
}

export interface CollectionWidget {
	collectionContainerHtml: string;
}

export interface FollowWidget {
	isFollowing: boolean;
	followChannelEmitter?: EventEmitter<boolean>;
}

export interface ListWidget {
	contentList: Content[];
}

export interface GenericHtmlWidget {
	outerHtml: SafeHtml;
}

export interface ZoomWidget {
	sdkKey: string;
	meetingNumber: string;
}

export interface Rep {
	nome: string;
	cognome: string;
	foto: string;
	stanza: string;
	mail: string;
}

export interface RepBlogWidget {
	rep$: Observable<Rep>;
}

export interface IframeWidget {
	iFrameResize$: Subject<resizeEmitterStruct>;
	saveClickEmitter?: EventEmitter<string>;
	resizeEmitter?: EventEmitter<resizeEmitterStruct>;
}

// distinguo se l'utente è già iscritto o se è un nuovo iscritto
export enum FadEnrollmentType {
	NEW = 1,
	OLD = 2
}

export interface FadEnrollment {
	errore: number;
	idAnagrafica: number;
	messaggioErrore: string;
}

export interface FadEnrollmentWidget {
	clientInfo$?: Observable<ExternalClientInfo>;
  fadId: number;
  alreadyEnrolled$: Observable<FadEnrollmentType>;
  sendMailFadDetailsEmitter?: EventEmitter<any>;
  saveEnrollEmitter: EventEmitter<{ecmForm: FormGroup<EcmForm> , fadId: number,clientId: string,consentCheck:boolean,ecmCheck:boolean}>;
}

export interface NewWidgetData {
	currentElement: PageElement;
	idContainer?: number;
	parametersToken?: ParametersToken;
	user?: UserDTO;
	owner: string;
	outerClass?: string;
	widgetData?:
		| PaymentFormWidget
		| CollectionWidget
		| IframeWidget
		| FollowWidget
		| ListWidget
		| GenericHtmlWidget
		| ZoomWidget
		| RepBlogWidget
    | FadEnrollmentWidget
		| {};
}

export interface NewWidget {
	data: NewWidgetData;
}

export interface EcmForm {
  consentInfos: FormGroup;
  ecmInfos: FormGroup<UserFormEcmInfos>;
  reclutato: FormControl<null | boolean>;
  rilascio_crediti: FormControl<null | boolean>;
}

export interface UserToEnroll {
  userId: number;
  consentFlags: boolean[];
  cellulare: string;
  cap: string;
  idComuneEsercizio: number;
  idTipoProfessione: number;
  reclutato: boolean;
  rilascio_crediti: boolean;
}

export function readPropertyInsideDelimiters(
	startingToken: string,
	closingToken: string,
	substitutionVariable: string
): string {
	return substitutionVariable.replace(startingToken, '').replace(closingToken, '');
}

export function substituteVariables(
	text: string,
	currentElement: PageElement | Content | ParametersToken,
  actualContentList?: Content[]
): string {
	if (!text) {
		return 'You passed an empty template';
	}

	let result: string = text;

	const regexMatcher: RegExp = new RegExp(
		'\\' + STARTING_DELIMITER + '(.+?)' + CLOSING_DELIMITER,
		'g'
	);

	const variablesMatching: string[] = text.match(regexMatcher);


	if (variablesMatching?.length > 0) {
		for (const substitutionVariable of variablesMatching) {
			const propertyName: string = readPropertyInsideDelimiters(
				STARTING_DELIMITER,
				CLOSING_DELIMITER,
				substitutionVariable
			);

			if (isPageElement(currentElement) && propertyName === COLLECTION_INJECTION_LABEL) {


				let collectionElementsHtml: string = '';

				// for (const collectionElement of currentElement.contentList) {
          for (const collectionElement of actualContentList) {
					collectionElementsHtml += substituteVariables(
						collectionElement.htmlDetail,
						collectionElement
					);
				}


				result = replaceAll(result, substitutionVariable, collectionElementsHtml);
			} else {
				// If the field is a date, first format it
				if (propertyName === 'dataCreazione') {
					result = replaceAll(
						result,
						substitutionVariable,
						new Date(currentElement[propertyName]).toLocaleDateString()
					);
				} else {
					result = replaceAll(result, substitutionVariable, currentElement[propertyName]);
				}
			}
		}
	}

	return result;
}
