import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../error/error-message.model';
import { Specialty } from './specialty.model';

export const loadSpecialty = createAction('[Specialty] LoadSpecialty');

export const loadSpecialtySuccess = createAction(
	'[Specialty] loadSpecialtySuccess',
	props<{ datiSpecialty: Specialty[] }>()
);
export const loadSpecialtyFailure = createAction(
	'[Specialty] loadSpecialtyFailure',
	props<{ error: ErrorMessage }>()
);
