import { loadCategory, loadCategorySuccess, loadCategoryFailure } from './category.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { CategoryState, categoryAdapter, categoryInitialState } from './category.entity';

export const reducer = createReducer(
	categoryInitialState,
	on(loadCategory, (state) => ({ ...state, error: undefined })),
	on(loadCategorySuccess, (state, action) => categoryAdapter.setAll(action.datiCategory, state)),
	on(loadCategoryFailure, (state, action) => ({ ...state, error: action.error }))
);

export function categoryReducer(
	state: CategoryState | undefined,
	action: Action
): CategoryState {
	return reducer(state, action);
}
