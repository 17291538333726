import { createReducer, on } from '@ngrx/store';
import { DNOperationState } from '../../shared/util/util';
import {
	activateUser,
	activateUserFailure,
	loadUserGuest,
	loadUserSoft,
	loadUserSoftFailure,
	loadUserSoftSuccess,
	loadUserSuccess,
	registerUser,
	registerUserFailure,
	registerUserSuccess,
	resetUser,
	retrievePassword,
	retrievePasswordFailure,
	retrievePasswordSuccess,
	saveUserInStore,
	setPremiumInStore,
	updateUser,
	updateUserFailure,
	updateUserSuccess
} from './user.actions';
import { GUEST_USER, UserState } from './user.model';

export const userInitialState: UserState = {
	user: undefined,
	error: undefined,
	userOperation: DNOperationState.INIT
};

export const userReducer = createReducer(
	userInitialState,
	on(saveUserInStore, (state, { utente }) => ({
		...state,
		user: utente
	})),
	on(resetUser, (state) => ({
		...userInitialState
	})),
	on(setPremiumInStore, (state, { premium, stripeId }) => ({
		...state,
		user: {
			...state.user,
			premium: premium,
			stripeId: stripeId
		}
	})),
	// on(loadUser, (state) => ({ ...state })),
	on(loadUserSuccess, (state, { utente }) => ({
		...state,
		user: { ...utente },
		error: undefined,
		userOperation: DNOperationState.OK
	})),

	on(loadUserGuest, (state) => ({
		...state,
		user: {
			...state.user,
			...GUEST_USER
		}
	})),

	on(loadUserSoft, (state) => ({ ...userInitialState, userOperation: DNOperationState.LOADING })),
	on(loadUserSoftSuccess, (state, { utente }) => ({
		...state,
		user: {
			...utente
		},
		userOperation: DNOperationState.OK
	})),
	on(loadUserSoftFailure, (state, { error }) => ({
		...userInitialState,
		error,
		userOperation: DNOperationState.ERROR
	})),

	on(registerUser, (state) => ({
		...state,
		userOperation: DNOperationState.LOADING
	})),
	on(registerUserSuccess, (state) => ({
		...state,
		userOperation: DNOperationState.OK
	})),
	on(registerUserFailure, (state, { error }) => ({
		...userInitialState,
		error,
		userOperation: DNOperationState.ERROR
	})),

	on(activateUser, (state) => ({
		...state,
		error: undefined
	})),
	on(activateUserFailure, (state, { error }) => ({
		...state,
		error
	})),

	on(updateUser, (state) => ({
		...state,
		userOperation: DNOperationState.LOADING
	})),
	on(updateUserSuccess, (state) => ({
		...state,
		userOperation: DNOperationState.OK
	})),
	on(updateUserFailure, (state) => ({
		...state,
		userOperation: DNOperationState.ERROR
	})),

	on(retrievePassword, (state) => ({
		...state,
		userOperation: DNOperationState.LOADING
	})),
	on(retrievePasswordSuccess, (state) => ({
		...state,
		userOperation: DNOperationState.OK
	})),
	on(retrievePasswordFailure, (state) => ({
		...state,
		userOperation: DNOperationState.ERROR
	}))
);
