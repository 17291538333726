import { SafeHtml } from '@angular/platform-browser';
import { Content } from '../../../../domain/content/content.model';
import {
	NewWidgetsTemplateId,
	ParametersToken,
	resizeEmitterStruct
} from '../../dynamic-container/new-widgets/new-widget.model';
import { EventEmitter } from '@angular/core';
import { UserDTO } from '../../../../domain/user/user.model';
import { Subject } from 'rxjs';
import { PageElement } from '../../../../domain/dynamic-container/dynamic-container.model';

export enum LegacyTemplateIdEnum {
	VIDEO = 4,
	ARTICOLO_RACCOLTA = 7,
	VIDEO_RACCOLTA = 12,
	FILE_RACCOLTA = 13,
	GENERIC_RACCOLTA = 52,
	HTML_GENERIC = 55,
	IFRAME = 57,
	LINK_RACCOLTA = 65,
	REP_BLOG = 161
}

export const isLegacyWidgetValid = (templateId: number) =>
	Object.values(LegacyTemplateIdEnum).includes(templateId);

export const isTemplateRaccolta = (templateId: number) =>
	[
		LegacyTemplateIdEnum.ARTICOLO_RACCOLTA,
		LegacyTemplateIdEnum.VIDEO_RACCOLTA,
		LegacyTemplateIdEnum.GENERIC_RACCOLTA,
		LegacyTemplateIdEnum.LINK_RACCOLTA,
		LegacyTemplateIdEnum.FILE_RACCOLTA,
		NewWidgetsTemplateId.NEW_COLLECTION,
		NewWidgetsTemplateId.NEW_CAROUSEL,
		NewWidgetsTemplateId.MENU
	].includes(templateId);

// le estensioni dei CollectionTypeEnum rispecchiano il modo in cui sono chiamati i div nei minisiti del vecchio dottnet
export enum CollectionTypeEnum {
	GENERIC = 'file-widget',
	LINK = 'default-widget',
	VIDEO = 'videoraccolta-widget',
	ARTICOLO = 'raccolta'
}
export interface IframeWidget {
	iFrameResize$: Subject<resizeEmitterStruct>;
	saveClickEmitter?: EventEmitter<string>;
	resizeEmitter?: EventEmitter<resizeEmitterStruct>;
}

export interface LegacyWidgetData {
	currentElement: PageElement;
	idContainer?: number;
	outerClass?: string;
	idRaccolta?: number;
	collectionType?: CollectionTypeEnum;
	url?: string;
	descrizioneTopic?: string;
	dataCreazione?: Date;
	parametersToken?: ParametersToken;
	user?: UserDTO;
	owner: string;
	widgetData?: IframeWidget | {};
}

export interface LegacyWidget {
	data: LegacyWidgetData;
	saveClickEmitter?: EventEmitter<string>;
	resizeEmitter?: EventEmitter<resizeEmitterStruct>;
}
