import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../error/error-message.model';
import { Province } from './province.model';

export const loadProvince = createAction('[Province] LoadProvince');

export const loadProvinceSuccess = createAction(
	'[Province] loadProvinceSuccess',
	props<{ datiProvince: Province[] }>()
);
export const loadProvinceFailure = createAction(
	'[Province] loadProvinceFailure',
	props<{ error: ErrorMessage }>()
);
