import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import { catchError, filter, map, Observable, of, switchMap, take } from 'rxjs';
import { EnvironmentService } from '../../../environments/environment.service';
import { ServerAuthData } from '../../core/auth/auth.models';
import { CallService } from '../../core/call-service/browser/call.service';
import { LogService } from '../../core/log/log.service';
import {
	getActivateUserUrl,
	getChangePassword,
	getCheckCFUrl,
	getCheckUserMailUrl,
	getRegisterUserUrl,
	getResendActivationMailUrl,
	getRetrievePassword,
	getUpdateUserUrl,
	getUserByIdUrl
} from '../../core/router/routes-provider';
import { ErrorMessage } from '../error/error-message.model';
import { ListaGruppiEnum, PasswordChange, ResendEmailDTO, User, UserDTO, UserWithCallback } from './user.model';
import { selectUserId } from './user.selectors';

export interface CheckUserFieldExistence {
	idEsiste: number;
}


@Injectable({
	providedIn: 'root'
})
export class UserService {
	constructor(
		private callService: CallService,
		private logService: LogService,
	) {}

	loadUser(idAnagrafica: number): Observable<UserDTO> {
		const url: string = getUserByIdUrl(idAnagrafica);

		const result = this.callService.CallApi<UserDTO>('GET', url, undefined);
		return result;
	}

	registerUser(user: UserWithCallback): Observable<ServerAuthData> {
		const urlToCall = getRegisterUserUrl();
		this.logService.info('Calling ', urlToCall);

		const result = this.callService.CallApi<ServerAuthData>(
			'POST',
			urlToCall,
			JSON.stringify(user)
		);
		return result;
	}

	activateUser(token: string): Observable<ServerAuthData> {
		const urlToCall = getActivateUserUrl(token);
		this.logService.info('Calling ', urlToCall);

		const result = this.callService.CallAuth<ServerAuthData>('POST', urlToCall, token);

		return result;
	}

	resendActivationMail(resendData:ResendEmailDTO) {
		const urlToCall = getResendActivationMailUrl();
		this.logService.info('Calling ', urlToCall);

		const result = this.callService.CallApi('POST', urlToCall,resendData);

		return result;
	}

	retrievePassword(email: string) {
		const urlToCall = getRetrievePassword(email);
		this.logService.info('Calling ', urlToCall);

		const result = this.callService.CallApi('POST', urlToCall);

		return result;
	}

	changePassword(passwordChange: PasswordChange) {
		const urlToCall = getChangePassword();
		this.logService.info('Calling ', urlToCall);

		const result = this.callService.CallApi(
			'POST',
			urlToCall,
			JSON.stringify(passwordChange));

		return result;
	}
	updateUser(user: User) {
		const urltoCall = getUpdateUserUrl();
		this.logService.info('Calling ', urltoCall);
		const result = this.callService.CallApi<UserDTO>('PATCH', urltoCall, JSON.stringify(user));
		return result;
	}

	getListaGruppiFromString(listaGruppi: string): ListaGruppiEnum[] {
		const resultList: ListaGruppiEnum[] = [];

		if (listaGruppi?.includes('anagrafica')) resultList.push(ListaGruppiEnum.Personal);
		if (listaGruppi?.includes('username_password')) resultList.push(ListaGruppiEnum.Personal);
		if (listaGruppi?.includes('dati_professionali')) resultList.push(ListaGruppiEnum.Professional);
		if (listaGruppi?.includes('consensi')) resultList.push(ListaGruppiEnum.Consents);

		return resultList;
	}


	checkMailExistence = (): AsyncValidatorFn => (
		control: AbstractControl
	): Observable<ValidationErrors | null> =>
		this.checkMailExistenceInternal(control.value).pipe(
			map((response: CheckUserFieldExistence) =>
				response.idEsiste === 0 ? null : { mailExistence: true }
			),
			catchError((error: ErrorMessage) => of({ mailExistence: true }))
		);


	// Validation
	checkMailExistenceInternal(mail: string, userId: number = -1) {
		const urltoCall = getCheckUserMailUrl(mail, userId);
		this.logService.info('Calling ', urltoCall);
		const result = this.callService.CallApi<CheckUserFieldExistence>('GET', urltoCall);
		return result;
	}

	checkCFExistence = (store: Store): AsyncValidatorFn => (
		control: AbstractControl
	): Observable<ValidationErrors | null> =>
		store.select(selectUserId).pipe(
			// Needed to complete observable and trigger async validation
			take(1),
      filter(() => control.value.length >= 16),
			switchMap((userId) =>
				this.checkCFExistenceInternal(control.value, userId).pipe(
					map((response: CheckUserFieldExistence) =>
						response.idEsiste === 0 ? null : { cfExistence: true }
					),
					catchError((error: ErrorMessage) => of({ cfExistence: true }))
				)
			)
		);


	checkCFExistenceInternal(cf: string, userId: number = -1) {
		const urltoCall = getCheckCFUrl(cf, userId);
		this.logService.info('Calling ', urltoCall);
		const result = this.callService.CallApi<CheckUserFieldExistence>('GET', urltoCall);
		return result;
	}
}
