import { Action, createReducer, on } from '@ngrx/store';
import { setCookieConsent } from './cookie-consent.actions';
import { CookieConsentState } from './cookie-consent.model';

export const cookieConsentInitialState: CookieConsentState = {
	functional_google_analytics: false,
	marketing_google_analytics: false,
	marketing_meta: false,
	status: false
};

const reducer = createReducer(
	cookieConsentInitialState,
	on(setCookieConsent, (state, { cookieConsent }) => ({
		...state,
		functional_google_analytics: cookieConsent.functional_google_analytics,
		marketing_google_analytics: cookieConsent.marketing_google_analytics,
		marketing_meta: cookieConsent.marketing_meta,
		status: cookieConsent.status
	}))
);

export function cookieConsentReducer(
	state: CookieConsentState | undefined,
	action: Action
): CookieConsentState {
	return reducer(state, action);
}
