import { createAction, props } from '@ngrx/store';
import { Content } from '../content/content.model';
import { ErrorMessage } from '../error/error-message.model';

// Rubriche

export const loadHomeWidgets = createAction('[Widget] LoadHomeWidgets');

export const loadMedlex = createAction(
	'[Widget] LoadMedlex',
	props<{
		userId: number;
	}>()
);

export const loadMedlexSuccess = createAction(
	'[Widget] LoadMedlexSuccess',
	props<{ medlexData: Content[] }>()
);
export const loadMedlexFailure = createAction(
	'[Widget] LoadMedlexFailure',
	props<{ error: ErrorMessage }>()
);

export const loadMinisterialBulletin = createAction(
	'[Widget] LoadMinisterialBulletin',
	props<{
		userId: number;
	}>()
);

export const loadMinisterialBulletinSuccess = createAction(
	'[Widget] LoadMinisterialBulletinSuccess',
	props<{ ministerialBulletinData: Content[] }>()
);
export const loadMinisterialBulletinFailure = createAction(
	'[Widget] LoadMinisterialBulletinFailure',
	props<{ error: ErrorMessage }>()
);

export const loadAifa = createAction(
	'[Widget] LoadtAifa',
	props<{
		userId: number;
	}>()
);

export const loadAifaSuccess = createAction(
	'[Widget] LoadAifaSuccess',
	props<{ aifaData: Content[] }>()
);
export const loadAifaFailure = createAction(
	'[Widget] LoadAifaFailure',
	props<{ error: ErrorMessage }>()
);

export const loadInstitutions = createAction(
	'[Widget] LoadInstitutions',
	props<{
		userId: number;
	}>()
);

export const loadInstitutionsSuccess = createAction(
	'[Widget] LoadInstitutionsSuccess',
	props<{ institutionsData: Content[] }>()
);
export const loadInstitutionsFailure = createAction(
	'[Widget] LoadInstitutionsFailure',
	props<{ error: ErrorMessage }>()
);

export const loadMainHighlight = createAction(
	'[Widget] LoadMainHighlight',
	props<{
		userId: number;
	}>()
);

export const loadMainHighlightSuccess = createAction(
	'[Widget] LoadMainHighlightSuccess',
	props<{ highlightData: Content }>()
);
export const loadMainHighlightFailure = createAction(
	'[Widget] LoadMainHighlightFailure',
	props<{ error: ErrorMessage }>()
);

export const loadMostRead = createAction('[Widget] LoadMostRead', props<{ userId: number }>());

export const loadMostReadSuccess = createAction(
	'[Widget] LoadMostReadSuccess',
	props<{ mostReadData: Content[] }>()
);
export const loadMostReadFailure = createAction(
	'[Widget] LoadMostReadFailure',
	props<{ error: ErrorMessage }>()
);

export const loadLatestNews = createAction(
	'[Widget] LoadLatestNews',
	props<{ userId: number; fromWhere: number; howMany: number }>()
);

export const loadLatestNewsSuccess = createAction(
	'[Widget] LoadLatestNewsSuccess',
	props<{ latestNewsData: Content[] }>()
);
export const loadLatestNewsFailure = createAction(
	'[Widget] LoadLatestNewsFailure',
	props<{ error: ErrorMessage }>()
);

export const loadRelated = createAction(
	'[Widget] LoadRelated',
	props<{
		category: number;
		template: string;
		contentId: number;
		userId: number;
	}>()
);

export const loadRelatedSuccess = createAction(
	'[Widget] LoadRelatedSuccess',
	props<{ relatedData: Content[] }>()
);
export const loadRelatedFailure = createAction(
	'[Widget] LoadRelatedFailure',
	props<{ error: ErrorMessage }>()
);

export const loadInteresting = createAction(
	'[Interesting] LoadInteresting',
	props<{
		userId: number;
		category: string;
		contentId: number;
	}>()
);

export const loadInterestingSuccess = createAction(
	'[Interesting] LoadInterestingSuccess',
	props<{ interestingData: Content[] }>()
);
export const loadInterestingFailure = createAction(
	'[Interesting] LoadInterestingFailure',
	props<{ error: ErrorMessage }>()
);

export const loadLatestVideos = createAction(
	'[Widget] LoadLatestVideos',
	props<{
		userId: number;
	}>()
);

export const loadLatestVideosSuccess = createAction(
	'[Widget] LoadLatestVideosSuccess',
	props<{ latestVideosData: Content[] }>()
);
export const loadLatestVideosFailure = createAction(
	'[Widget] LoadLatestVideosFailure',
	props<{ error: ErrorMessage }>()
);

export const loadFocus = createAction(
	'[Widget] LoadFocus',
	props<{
		userId: number;
	}>()
);

export const loadFocusMainSuccess = createAction(
	'[Widget] LoadFocusMainSuccess',
	props<{ focusMainData: Content }>()
);
export const loadFocusMainFailure = createAction(
	'[Widget] LoadFocusMainFailure',
	props<{ error: ErrorMessage }>()
);
export const loadFocusListSuccess = createAction(
	'[Widget] LoadFocusListSuccess',
	props<{ focusListData: Content[] }>()
);
export const loadFocusListFailure = createAction(
	'[Widget] LoadFocusListFailure',
	props<{ error: ErrorMessage }>()
);

export const loadFocusVideo = createAction(
	'[Widget] LoadFocusVideo',
	props<{
		userId: number;
	}>()
);

export const loadFocusVideoMainSuccess = createAction(
	'[Widget] LoadFocusVideoMainSuccess',
	props<{ focusVideoMainData: Content }>()
);
export const loadFocusVideoMainFailure = createAction(
	'[Widget] LoadFocusVideoMainFailure',
	props<{ error: ErrorMessage }>()
);
export const loadFocusVideoListSuccess = createAction(
	'[Widget] LoadFocusVideoListSuccess',
	props<{ focusVideoListData: Content[] }>()
);
export const loadFocusVideoListFailure = createAction(
	'[Widget] LoadFocusVideoListFailure',
	props<{ error: ErrorMessage }>()
);

export const loadInsights = createAction('[Widget] LoadInsights', props<{ userId: number }>());

export const loadInsightsSuccess = createAction(
	'[Widget] LoadInsightsSuccess',
	props<{ insightsData: Content[] }>()
);
export const loadInsightsFailure = createAction(
	'[Widget] LoadInsightsFailure',
	props<{ error: ErrorMessage }>()
);
