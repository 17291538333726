import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { ContentService } from '../../domain/content/content.service';
import { ErrorMessage, ErrorType } from '../../domain/error/error-message.model';
import { decodeError } from '../../domain/error/error.util';
import { LogEntry } from '../../domain/log/log.model';
import { selectUserId } from '../../domain/user/user.selectors';
import { LogDbService } from '../local-db/logdb.service';
import { LogService } from '../log/log.service';
import {
	saveError,
	saveErrorFailure,
	saveErrorSuccess
} from './error.actions';
import { onlyWhenOnline } from '../../shared/util/operators';
import { generateGuid } from '../../shared/util/util';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ErrorEffects {
	saveError$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveError),
			tap((action) => this.logService.info('Effect: ', action.type)),
			onlyWhenOnline(this.store),
			map(({ error }) => decodeError(error,this.translate)),
			concatLatestFrom(() => this.store.select(selectUserId)),
			switchMap(([errorMessage, userId]) => {
				this.logService.info('Unmanaged error', errorMessage);
				if (errorMessage.Code !== ErrorType.ErrorBackendDown) {
					const logEntry: LogEntry = this.logDbService.getLogFromError(errorMessage, userId);
					const sessionGuid: string = generateGuid();

					return this.contentService.saveLogsToBackend([logEntry], sessionGuid)
						.pipe(
							tap((response) =>
								this.logService.infoDebug('Effect: saveErrorSuccess ', response)
							),
							map(() => saveErrorSuccess({ sessionGuid })),
							catchError((error) => of(saveErrorFailure({ error })))
						)
				} else {
					return of(saveErrorFailure({error:errorMessage}));
				}
			})
		)
	);

	saveErrorSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveErrorSuccess),
			tap((action) => this.logService.info('Effect: saveErrorSuccess', action)),
			switchMap((sessionGuid) => of(sessionGuid))
		),
		{ dispatch: false }
	);

	constructor(
		private actions$: Actions,
		private logService: LogService,
		private logDbService: LogDbService,
		private contentService: ContentService,
		private store: Store,
    private translate: TranslateService
	) { }
}
