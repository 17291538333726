import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorMessage } from '../error/error-message.model';
import { Province } from './province.model';

export const provinceAdapter: EntityAdapter<Province> = createEntityAdapter<Province>({
	selectId: (province) => province.idProvincia
});
export interface ProvinceState extends EntityState<Province> {
	error: ErrorMessage;
}
export const provinceInitialState: ProvinceState = provinceAdapter.getInitialState({
	error: undefined
});
export const provinceSelectors = provinceAdapter.getSelectors();
