import { Action, createReducer, on } from '@ngrx/store';
import { InitState } from './init.models';
import { dnLivreaConfigSuccess } from './init.actions';

export const initInitialState: InitState = {
	livreaActive: false
};

const reducer = createReducer(
	initInitialState,
	on(dnLivreaConfigSuccess, (state,action) => ({
		...state,
		livreaActive: action.livreaActive
	}))
);

export function initReducer(state: InitState | undefined, action: Action): InitState {
	return reducer(state, action);
}
