import { ComponentType } from '@angular/cdk/portal';
import { createAction, props } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { Observable } from 'rxjs';
import { Content } from '../../domain/content/content.model';
import { ErrorMessage } from '../../domain/error/error-message.model';
import { ContentPermission } from './permission-dialog/permission.model';
import { PasswordChange } from '../../domain/user/user.model';

export const loadDialog = createAction(
	'[DottnetDialog] LoadOpenDialog',
	props<{
		contentId: number;
		template: string;
		componentType: ComponentType<any>;
		contextUrl: string;
		dataDialog?: any;
	}>()
);

export const openDialog = createAction(
	'[DottnetDialog] OpenDialog',
	props<{
		content: Content | undefined;
		urlToDownload: string;
		urlWithContext: string;
		componentType: ComponentType<any>;
		panelClass?: string;
		disableClose?: boolean;
		data?: any;
	}>()
);

export const closeDialog = createAction('[DottnetDialog] CloseDialog');

export const errorDialog = createAction(
	'[DottnetDialog] ErrorDialog',
	props<{ error: ErrorMessage }>()
);

export const checkPermissionDialog = createAction(
	'[DottnetDialog] checkPermissionDialog',
	props<{
		templateVisibility: number;
		callbackUrl:string;
		selectorAbilitato?: Observable<ContentPermission>;
	}>()
);

export const evaluatePermissionDialog = createAction(
	'[DottnetDialog] evaluatePermissionDialog',
	props<{
		templateVisibility: number;
		abilitatoSelector?: Observable<ContentPermission>;
		callbackUrl:string;
		showClose?: boolean;
		nextAction?: TypedAction<any>;
	}>()
);

export const updatePassword = createAction(
	'[DottnetDialog] changePasswordDialog',
	props<{ passwordChange: PasswordChange }>()
);

export const updatePasswordFailure = createAction(
	'[User] updatePasswordFailure',
	props<{ error: ErrorMessage }>()
);
