import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { selectAuthLoginState } from '../../core/auth/auth.selectors';
import { LogService } from '../../core/log/log.service';
import { onlyWhenOnline, onlyWhenAuthenticated } from '../../shared/util/operators';
import { ErrorMessage } from '../error/error-message.model';
import { manageError } from '../error/error.util';
import { loadSpecialty, loadSpecialtyFailure, loadSpecialtySuccess } from './specialty.actions';
import { Specialty } from './specialty.model';
import { selectSpecialty } from './specialty.selectors';
import { SpecialtyService } from './specialty.service';

@Injectable({ providedIn: 'root' })
export class SpecialtyEffects {
	loadSpecialty$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadSpecialty),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap((action) => this.logService.info('Effect: loadSpecialty', action)),
			concatLatestFrom(() =>
				this.store.select(selectSpecialty).pipe(filter((specialties) => specialties.length === 0))
			),
			switchMap(() =>
				this.specialtyService.loadSpecialty().pipe(
					tap((data: Specialty[]) =>
						this.logService.infoDebug('Effect: loadSpecialty data received --> ', data)
					),
					map((datiSpecialty: Specialty[]) => loadSpecialtySuccess({ datiSpecialty })),
					catchError((error: ErrorMessage) => of(loadSpecialtyFailure({ error })))
				)
			)
		)
	);

	//  load failure
	loadSpecialtyFailure$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadSpecialtyFailure),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			tap((action) => this.logService.info('Effect: loadSpecialtyFailure ', action)),
			map(([{ error }, authLoginState]) => manageError(error, authLoginState))
		)
	);

	constructor(
		private actions$: Actions,
		private specialtyService: SpecialtyService,
		private logService: LogService,
		private store: Store
	) {}
}
