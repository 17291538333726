import {
	enableProdMode,
} from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app/app.component';
import {bootstrapApplication} from '@angular/platform-browser';
import { mergedConfig } from './app.config.client';
if (environment.production) {
	enableProdMode();
}

	bootstrapApplication(AppComponent, mergedConfig)
		// eslint-disable-next-line no-console
		.catch((err) => console.error(err));

