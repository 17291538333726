<div class="flex-col flex-justify-space-around flex-align-center signin-container">
    <div *ngIf="
			![PermissionVisualizationType.Signin, PermissionVisualizationType.NotifyPermission].includes(
				permissionType
			) && !isForgotPasswordForm
		">
        <p class="intro">
            {{ 'dottnet.permessi.sessionExpired' | translate }}
        </p>
    </div>
    <form class="form-container" [formGroup]="loginForm" novalidate (ngSubmit)="submitForm()" *ngIf="!isForgotPasswordForm">
        <div class="flex-col flex-justify-center flex-align-center form-wrapper">
            <div class="flex-col flex-justify-center flex-align-center form-wrapper input-login">
                <mat-form-field class="large-field">
                    <mat-label for="email">{{ 'dottnet.login.username' | translate }}</mat-label>
                    <input type="email" matInput placeholder="{{ 'dottnet.login.username' | translate }}" formControlName="email" />
                    <mat-error>{{ errorMessage('email') | translate }} </mat-error>
                </mat-form-field>

                <mat-form-field class="large-field">
                    <mat-label for="password">{{ 'dottnet.login.password' | translate }}</mat-label>
                    <input type="password" matInput placeholder="{{ 'dottnet.login.password' | translate }}" formControlName="password" />
                    <mat-error>{{ errorMessage('password') }} </mat-error>
                </mat-form-field>
            </div>
            <div class="under-password flex-row flex-justify-space-between flex-align-center">
                <mat-checkbox class="remember" id="rememberme" formControlName="rememberMe" [checked]="isChecked" name="rememberme" color="accent">
                    {{ 'dottnet.login.rememberme' | translate }}
                </mat-checkbox>
                <a (click)="switchForm()" class="cursor-pointer flex-row flex-justify-center flex-align-center">
                    <span class="forbidden-pwd">{{ forgotPasswordLabel }}</span
					>&nbsp;
					<mat-icon id="keyIcon" class="customIcon">vpn_key</mat-icon>
				</a>
			</div>
			<button
				class="large-field"
				[disabled]="!loginForm.valid"
				type="submit"
				mat-raised-button
				color="accent"
			>
				{{ 'dottnet.login.submit' | translate }}
			</button>
			<div class="under-button flex-row flex-justify-end flex-align-start">
				<span>
					Non hai un account?&nbsp; <a (click)="closeDialog()" [href]="signupLink">{{
						'dottnet.login.register' | translate
					}}</a></span
				>
			</div>
		</div>
	</form>

	<form
		[formGroup]="retrievePwdForm"
		novalidate
		(ngSubmit)="retrievePassword()"
		*ngIf="isForgotPasswordForm"
		class="form-container"
	>
		<div class="flex-col flex-justify-center flex-align-center form-wrapper">
			<mat-form-field class="large-field">
				<mat-label for="email">{{ 'dottnet.login.email' | translate }}</mat-label>
				<input
					type="email"
					matInput
					placeholder="{{ 'dottnet.login.email' | translate }}"
					formControlName="email"
				/>

				<mat-error>{{ errorMessage('email') }} </mat-error>
			</mat-form-field>
			<div class="under-password flex-row flex-justify-end flex-align-center">
				<a
					(click)="switchForm()"
					class="cursor-pointer flex-row flex-justify-center flex-align-center"
				>
					<span class="forbidden-pwd">{{ forgotPasswordLabel }}</span
					>&nbsp;
					<mat-icon class="customIcon">account_circle</mat-icon>
				</a>
			</div>

			<button
				[disabled]="!retrievePwdForm.valid"
				type="submit"
				mat-raised-button
				color="accent"
				class="large-field"
			>
				{{ 'dottnet.login.pwd' | translate }}
			</button>
		</div>
	</form>
</div>