import { createReducer, on } from '@ngrx/store';
import { loadTemplateCt } from './template-ct.actions';
import { DottnetTemplate, templateCtMap } from './template-ct.model';

export const templateCtInitialState: { templateCtMap: DottnetTemplate } = {
	templateCtMap: undefined
};

export const templateCtReducer = createReducer(
	templateCtInitialState,
	// eslint-disable-next-line arrow-body-style
	// on(loadTemplateCt, () => {
	// 	return templateCtMap;
	// })
	on(loadTemplateCt, (state) => ({ ...state, templateCtMap: templateCtMap }))
);
