export const globalClientDomainProd: string = 'https://www.dottnet.it';
export const globalServerDomainProd: string = 'https://www.dottnet.it';
export const globalAuthDomainProd: string = 'https://www.dottnet.it';

export const globalClientDomainDev: string = 'http://localhost:4200';
export const globalServerDomainDev: string = 'http://localhost:8900';
export const globalAuthDomainDev: string = 'http://localhost:8900';

export const globalClientDomainStaging: string = 'https://devnew.dottnet.it';
export const globalServerDomainStaging: string = 'https://devnew.dottnet.it';
export const globalAuthDomainStaging: string = 'https://devnew.dottnet.it';
