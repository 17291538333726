import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';
import { ErrorMessage } from '../error/error-message.model';
import { Topic } from './topic.model';
import { currentTopic, topicList } from './topic.reducer';

export interface TopicState {
	topicList: TopicListState;
	currentTopic: TopicCurrentState;
}

// List

export interface TopicListState extends EntityState<Topic> {
	error: ErrorMessage;
}

export const topicListAdapter: EntityAdapter<Topic> = createEntityAdapter<Topic>({
	selectId: (topicList) => topicList.topicID
});

export const topicListInitialState: TopicListState = topicListAdapter.getInitialState({
	error: undefined
});

export const topicListSelectors = topicListAdapter.getSelectors();

// Current

export interface TopicCurrentState extends EntityState<Topic> {
	error: ErrorMessage;
}

export const topicAdapter: EntityAdapter<Topic> = createEntityAdapter<Topic>({
	selectId: (topic) => topic.topicID
});

export const topicInitialState: TopicCurrentState = topicAdapter.getInitialState({
	error: <ErrorMessage>{}
});

export const topicSelectors = topicAdapter.getSelectors();
