import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../../domain/error/error-message.model';
import { LoginType, ServerAuthData, Token } from './auth.models';
import { ParamMap } from '@angular/router';
import { PayloadSignin } from '../../shared/dialog/signin-dialog/signin-form/signin-form.component';

export const setLocalStorageAuth = createAction(
	'[Auth] SetLocalStorageAuth',
	props<{ token: Token; loginType: LoginType }>()
);

export const authSetRenewToken = createAction('[Auth] SetRenewToken', props<{ token: Token }>());
export const authSetLoginType = createAction(
	'[Auth] SetLoginType',
	props<{ loginType: LoginType }>()
);

export const authHardLogin = createAction(
	'[Auth] HardLogin',
	props<{
		payloadSignin:PayloadSignin;
    params: ParamMap;
    oldSessionID: number;
    url: string;
    referrer: string
	}>()
);

export const authHardLoginSuccess = createAction(
	'[Auth] HardLoginSuccess',
	props<{ serverAuthData: ServerAuthData,
    params: ParamMap;
    oldSessionID: number;
    url: string;
    referrer: string }>()
);

export const authHardLoginFailure = createAction(
	'[Auth] HardLoginFailure',
	props<{ error: ErrorMessage }>()
);

export const authGuestLogin = createAction('[Auth] GuestLogin');
export const authGuestLoginSuccess = createAction(
	'[Auth] GuestLoginSuccess',
	props<{ serverAuthData: ServerAuthData }>()
);

export const authGuestLoginFailure = createAction(
	'[Auth] GuestLoginFailure',
	props<{ error: ErrorMessage }>()
);

export const authSoftLogin = createAction('[Auth] SoftLogin', props<{ token: string }>());

export const authSoftLoginSuccess = createAction(
	'[Auth] SoftLoginSuccess',
	props<{ serverAuthData: ServerAuthData }>()
);

export const authSoftLoginFailure = createAction(
	'[Auth] SoftLoginFailure',
	props<{ error: ErrorMessage }>()
);

export const authRenewToken = createAction('[Auth] RenewToken');

export const authRenewTokenSuccess = createAction(
	'[Auth] RenewTokenSuccess',
	props<{ serverAuthData: ServerAuthData }>()
);

export const authRenewTokenFailure = createAction(
	'[Auth] RenewTokenFailure',
	props<{ error: ErrorMessage }>()
);

// export const authRenewTokenNotNeeded = createAction('[Auth] RenewTokenNotNeeded');

export const authLogout = createAction('[Auth] Logout');
export const authLogoutAndGuest = createAction('[Auth] LogoutAndGuest');
export const authLogoutAndHard = createAction('[Auth] LogoutAndHard');

export const authNotLogged = createAction('[Auth] NotLogged');

export const authOffline = createAction('[Auth] Offline', props<{ error: ErrorMessage }>());
export const authOnline = createAction('[Auth] Online');
export const authCheckOnline = createAction('[Auth] CheckOnline');

// User Interactions

export const authLoadingUser = createAction('[Auth] LoadingUser');

export const authUserLoadedHard = createAction('[Auth] AuthUserLoadedHard');

export const authUserLoadedSoft = createAction('[Auth] AuthUserLoadedSoft');

export const authUserLoadedGuest = createAction('[Auth] AuthUserLoadedGuest');

export const noOpAction = createAction('[Auth] NoOpAction');
