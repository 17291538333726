import { ParamMap, Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../error/error-message.model';
import { User, UserDTO } from './user.model';

export const saveUserInStore = createAction('[User] SaveUserInStore', props<{ utente: UserDTO }>());

export const resetUser = createAction('[User] ResetUser');

export const loadUser = createAction('[User] LoadUser', props<{ idAnagrafica: number }>());

export const loadUserSuccess = createAction('[User] LoadUserSuccess', props<{ utente: UserDTO }>());

export const loadUserFailure = createAction(
	'[User] LoadUserFailure',
	props<{ error: ErrorMessage }>()
);

// Added nullable utente to match other loadUser-Success calls to make it interceptable in user.effects -> setLogServiceUser$
export const loadUserGuest = createAction('[User] LoadUserGuest', props<{ utente?: UserDTO }>());

export const loadUserSoft = createAction('[User] LoadUserSoft', props<{ idAnagrafica: number }>());

export const loadUserSoftSuccess = createAction(
	'[User] LoadUserSoftSuccess',
	props<{ utente: UserDTO }>()
);

export const loadUserSoftFailure = createAction(
	'[User] LoadUserSoftFailure',
	props<{ error: ErrorMessage }>()
);

export const registerUser = createAction(
	'[User] registerUser',
	props<{ newUser: User; queryParams?: Params,
    params: ParamMap;
    oldSessionID: number;
    url: string;
    referrer: string }>()
);

export const registerUserSuccess = createAction('[User] registerUserSuccess');

export const registerUserFailure = createAction(
	'[User] registerUserFailure',
	props<{ error: ErrorMessage }>()
);

export const updateUser = createAction('[User] updateUser', props<{ newUser: User }>());

export const updateUserSuccess = createAction(
	'[User] updateUserSuccess',
	props<{ newUser: UserDTO }>()
);

export const updateUserFailure = createAction(
	'[User] updateUserFailure',
	props<{ error: ErrorMessage }>()
);

export const activateUser = createAction('[User] activateUser', props<{ token: string, callbackUrl: string,  params: ParamMap;
  oldSessionID: number;
  url: string;
  referrer: string }>());

export const activateUserFailure = createAction(
	'[User] activateUserFailure',
	props<{ error: ErrorMessage, callbackUrl:string }>()
);

export const resendActivationMail = createAction('[User] ResendActivationMail',
	props<{callbackUrl:string}>()
);
export const resendActivationMailSuccess = createAction('[User] ResendActivationMailSuccess');
export const resendActivationMailFailure = createAction(
	'[User] ResendActivationMailFailure',
	props<{ error: ErrorMessage }>()
);

export const retrievePassword = createAction('[User] retrievePassword', props<{ email: string }>());
export const retrievePasswordSuccess = createAction('[User] retrievePasswordSuccess');
export const retrievePasswordFailure = createAction(
	'[User] retrievePasswordFailure',
	props<{ error: ErrorMessage }>()
);

export const setPremiumInStore = createAction(
	'[User] setPremiumInStore',
	props<{ premium: boolean; stripeId: string }>()
);
