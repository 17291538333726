import { HttpErrorResponse } from '@angular/common/http';
import { TypedAction } from '@ngrx/store/src/models';
import {
	authGuestLogin,
	authLogoutAndGuest,
	authLogoutAndHard,
	authOffline,
	authRenewToken,
	noOpAction
} from '../../core/auth/auth.actions';
import {
	AuthLoginState,
	AuthProgress,
	BackendState,
	HealthCheck
} from '../../core/auth/auth.models';
import { HttpStatusCode } from '../../core/http-status/http-status.model';
import { navigateSignin } from '../../core/router/routes.actions';
import { errorActions, ErrorMessage, ErrorType, ActionsAfterError, errorsToBeTranslated } from './error-message.model';
import {DexieError} from 'dexie';
import { TranslateService } from '@ngx-translate/core';

export function isErrorMessage(error: any): error is ErrorMessage {
	return (<ErrorMessage>error)?.Code !== undefined;
}

export function isDexieError(error: any): error is DexieError {
	return (<DexieError>error)?.inner !== undefined;
}
export function isHealthCheck(error: any): error is HealthCheck {
	return (<HealthCheck>error)?.status !== undefined;
}

//  decodeError takes a generic error as input and creates a typed error message
//  the internal struct is errorMessage, that can be either an httperror, a generic error, or
//  a typed error generated from OS or Filesystem
//  if the error is an httperror, it is parsed and finally the body is inspected to find the
//  a typed error generated from DottNet apis
export function decodeError(error: Error | HttpErrorResponse | ErrorMessage | DexieError, translate: TranslateService): ErrorMessage {
	const errorMessage: ErrorMessage = new(ErrorMessage);

	// this defines errorMessage as an ErrorMessage
	errorMessage.Code = ErrorType.ErrorNotAssigned;

	if (isErrorMessage(error)) {
		return error;
	}



	if (error instanceof HttpErrorResponse) {
		const httpErr: HttpErrorResponse = error;
		// error è già un oggetto nativo TS
		//  quindi poiché ha Code e Message (che sono stati ritornati dal webservice),
		//  vanno automaticamente nei rispettivi campi di errorMessage, io poi li leggo come enum

		// first we check if backend is down 0 or 502 bad gateway or 503 service unavailable
		if (
			httpErr.status === 0 ||
			httpErr.status === HttpStatusCode.BadGateway ||
			httpErr.status === HttpStatusCode.ServiceUnavailable
		) {
			// backend is down
			errorMessage.Code = ErrorType.ErrorBackendDown;
			if (isHealthCheck(httpErr.error)) {
				errorMessage.hint = '';
				Object.keys(httpErr.error.errors).forEach((key) => {
					if (key !== 'type') {
						errorMessage.hint += ' ' + httpErr.error.errors[key];
					}
				});

				// httpErr.error.errors.forEach((k, v) => (errorMessage.Hint += ' ' + v));
			}
		} else {
			if (typeof httpErr?.error?.Code !== 'undefined') {
				errorMessage.Code = <ErrorType>httpErr.error.Code;
			} else {
				errorMessage.Code = ErrorType.ErrorUnMapped;
			}
		}

		if (typeof httpErr?.error?.Message !== 'undefined') {
			errorMessage.messageDN = httpErr.error.Message;
		} else {
			if (typeof httpErr.message !== 'undefined') {
				errorMessage.messageDN = httpErr.message;
			}
		}

		errorMessage.name = error?.name;
		errorMessage.httpStatus = httpErr.status;
		errorMessage.httpStatusText = httpErr.statusText;
		errorMessage.url = httpErr.url;
		if (!errorMessage?.hint) {
			errorMessage.hint = httpErr?.error?.Hint;
		}
	}
	if (isDexieError(error)) {
		const dexieError: DexieError = <DexieError>error;
		errorMessage.Code = ErrorType.ErrorDexieError;
		errorMessage.message = dexieError.message;
		if (dexieError?.stack) {
			errorMessage.callStack = dexieError.stack;
		}

	}
	if (error instanceof Error) {
		errorMessage.Code = ErrorType.ErrorUnMapped;
		errorMessage.message = error.message;
		if (error?.stack) {
			errorMessage.callStack = error.stack;
		}
	}

  if (translate != null &&  errorsToBeTranslated.has(errorMessage.Code)){
    errorMessage.messageDN = translate.instant(errorsToBeTranslated.get(errorMessage.Code))
  }else{
    errorMessage.messageDN = error.message
  }
	return errorMessage;
}




//  Helper function to extract error, if there is one.
export function getAuthProgressError(authProgress: AuthProgress): ErrorMessage | null {
	if ((authProgress as ErrorMessage).Code !== undefined) {
		return authProgress as ErrorMessage;
	}
	return null;
}

export function getBackendStateError(backendState: BackendState): ErrorMessage | null {
	if ((backendState as ErrorMessage).Code !== undefined) {
		return backendState as ErrorMessage;
	}
	return null;
}

export function getNextActionFromError(errorType: ErrorType): ActionsAfterError {
	if (errorActions.has(errorType)) {
		return errorActions.get(errorType);
	} else {
		return ActionsAfterError.ERRORNOTPRESENT;
	}
}

export function manageError(
	error: Error | HttpErrorResponse | ErrorMessage,
	// failureAction: ({ error: errorMessage }) => TypedAction<any>
	authLoginState: AuthLoginState
): TypedAction<any> {
	const errorMessage: ErrorMessage = decodeError(error, null);
	//  next action can be:
	//  LOGIN: we  need to login again
	//  RENEW: we need to renew tokens
	//  OFFLINE: site went offline, redirect user to courtesy page
	//  TBD
	const nextAction: ActionsAfterError = getNextActionFromError(errorMessage.Code);

	switch (nextAction) {
		case ActionsAfterError.LOGOUTANDLOGIN:
			//  LOGOUTANDLOGIN: logout user and start new login
			switch (authLoginState) {
				case AuthLoginState.LOGGEDHARD:
				case AuthLoginState.LOGGEDSOFT:
					return authLogoutAndHard();
				case AuthLoginState.LOGGEDGUEST:
					return authLogoutAndGuest();
				case AuthLoginState.REFRESHING:
					// what to do here?
					return noOpAction();
				case AuthLoginState.NOTLOGGED:
					return noOpAction();
				default:
					return authLogoutAndGuest();
			}
			break;
		case ActionsAfterError.GUESTLOGIN:
			//  GUESTLOGIN: we make a guestlogin and go on
			return authGuestLogin();
		case ActionsAfterError.LOGIN:
			//  LOGIN: we  need to login again, redirect to login page
			// TODO: navigate to login
			return navigateSignin();
		case ActionsAfterError.OFFLINE:
			//  OFFLINE: site went offline, redirect user to courtesy page
			return authOffline({ error: errorMessage });
		case ActionsAfterError.RENEW:
			return authRenewToken();
		// TODO: manage SETERROR and ERRORNOTPRESENT
		case ActionsAfterError.SETERROR:
		case ActionsAfterError.ERRORNOTPRESENT:
			return noOpAction();
			}
}
