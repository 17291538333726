/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import Dexie, { Version } from 'dexie';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorMessage } from '../../domain/error/error-message.model';
import { decodeError, isErrorMessage } from '../../domain/error/error.util';
import { LogEntry, LogLevel, logLevelToString } from '../../domain/log/log.model';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
	providedIn: 'root'
})
export class LogDbService extends Dexie {
	//  Declare implicit table properties.
	//  (just to inform Typescript. Instanciated by Dexie in stores() method)
	// when everything is lost, increment version numner
	logs: Dexie.Table<LogEntry, number>; //  number = type of the primkey
	// ...other tables goes here...

	constructor(
    private translate: TranslateService
	) {
		super('DottNet');
    let   currentVersion: Version;
		currentVersion = this.version(6).stores({
			logs: '++id, messageDate,rowType'
			// ...other tables goes here...
		});

		//  The following line is needed if your typescript
		//  is compiled using babel instead of tsc:
		this.logs = this.table('logs');
	}

	async save(log: LogEntry) {
		//  Add or update our selves. If add, record this.id.
		return await this.transaction(
			'rw',
			this.logs,
			async () => await this.logs.add(log).catch((e) => this.handleError(e))
		);
	}

	getLogsCount(): Observable<number> {
		// Date of yesterday

		return from(
			this.logs
				// .where(('rowType'))
				// .aboveOrEqual(LogLevel[logLevel])
				.count()
		);
	}

	getLogsByDate(fromDate: Date): Observable<LogEntry[]> {
		const startDate: number = fromDate.getTime();


		return from(this.logs
			.where('messageDate')
			.aboveOrEqual(startDate)
			.reverse()
			.toArray());
	}

	// Query by logLevel in the latest day
	getLogsByLogLevel(logLevel: LogLevel): Observable<LogEntry[]> {

		const logLevelString: string = logLevelToString(logLevel);

		// Filter by logLevel
		return from(
			this.logs
				.where('rowType')
				.aboveOrEqual(LogLevel[logLevel])
				.reverse()
				.toArray()
		);
	}

    deleteAllLogs(): Observable<void>  {
		return from(this.logs.clear()
			);

	}
	deleteLogByDate(fromDate: Date): Observable<number | void> {
		// date in logs is represented by a unix date
		//  so getTime() needs to be divided by 1000
		const startTime: number = fromDate.getTime();

		return from(this.logs
			.where('messageDate')
			.below(startTime)
			.delete());
			/* .then(function (deleteCount) {
				deleted = deleteCount;
			}) */;

	}

	deleteAllLogsBeforeToday(): Observable<number | void> {
		return this.deleteLogByDate(new Date());
	}

	handleError(e) {
		switch (e.name) {
			case 'AbortError':
				if (e.inner) {
					return this.handleError(e.inner);
				}
				console.error('Abort error ' + e.message);
				break;
			case 'QuotaExceededError':
				console.error('QuotaExceededError ' + e.message);
				break;
			default:
				console.error(e);
				break;
		}
	}

	private compareLogLevel(currentItem: LogEntry, logLevel: string): boolean {
		return LogLevel[logLevel] >= LogLevel[currentItem.rowType];
	}

	getLogFromError(error: Error | ErrorMessage, userId: number): LogEntry {
		let escapedStack: string;
		let jsonStack: string = '';
		let errorMessage: ErrorMessage
		if (!isErrorMessage(error)) {
			errorMessage = decodeError(error, this.translate);
		} else {
			errorMessage = error;
		}
		if (errorMessage?.callStack) {
			try {
				jsonStack = JSON.stringify(errorMessage.callStack);
			}
			catch (e: any) {
				// manage Converting circular structure to Json with an ad hoc replacer
				jsonStack = JSON.stringify(errorMessage.callStack, this.getCircularReplacer);
			}
			escapedStack = jsonStack ? this.escapeJson(jsonStack) : '';
		}

		let message: string = '';
		if (error?.message && error.message.length > 0) {
			message = this.escapeJson(error.message);
		} else if (errorMessage?.messageDN && errorMessage.messageDN.length > 0) {
			message = this.escapeJson(errorMessage.messageDN);
		}
		const logEntry: LogEntry = new LogEntry(
			userId,
			'ERROR',
			Date.now(),
			message,
			0,
			!!escapedStack ? escapedStack : ''
		);

		return logEntry;

	}


	getCircularReplacer = () => {
		const seen = new WeakSet();
		return (key, value) => {
			if (typeof value === 'object' && value !== null) {
				if (seen.has(value)) {
					return;
				}
				seen.add(value);
			}
			return value;
		};
	};

	escapeJson(json: string) {
		const lunghezza: number = json.length;
		let newString: string = '';
		for (let i = 0; i < lunghezza; i++) {
			switch (json[i]) {
				case '\b':
				case '\t':
				case '\r':
				case '\n':
				case '\\':
				case "'":
				case '"':
					newString += '\\' + json[i];
					break;
				default:
					newString += json[i];
			}
		}

		return newString;
	}





}

/* export function migrationFactory() {
  return {
	2: (db, transaction) => {
	  const store = transaction.objectStore('log');
	  store.createIndex('messagedata', 'messagedata', { unique: false });
	}
  };
}
*/
