import { ContentPermission } from './../../shared/dialog/permission-dialog/permission.model';
import { Content } from '../content/content.model';
import { ParametersToken } from '../../container/dynamic-container/dynamic-container/new-widgets/new-widget.model';

export interface ContentBase {
	idContenuto: number;
	idTipoContenuto: number;
	idTemplate: number;
	template: string;
	loginCloseDisable: boolean;

}

export interface PageElement {
	idPadre: number;
	idContenuto: number;
	idTipoContenuto: number;
	pathFoto: string;
	titolo: string;
	occhiello: string;
	idAnagraficaAutore: number;
	autore: string;
	dataCreazione: number;
	descrizione: string;
	corpo: string;
	url: string;
	idTemplate: number;
	template: string;
	isContenitore: number;
	permalink: string;
	riga: number;
	colonna: number;
	runatServer: number;
	classe: string;
	parametri: string;
	target: string;
	widgetDetail: Content;
	contentList?: Content[];
	machform?: MachformField[];
	htmlContainer?: string;
}

// Type checker for PageElement || Content
export function isPageElement(
	element: PageElement | Content | ParametersToken
): element is PageElement {
	return 'contentList' in element;
}

export interface DottnetContainer {
	contenuto: ContentBase;
	contenutoDefault: ContentBase;
	contenutoBiografia: ContentBase;
	pathFotoHeader: string;
	socialFacebook: string;
	socialLinkedin: string;
	socialInstagram: string;
	socialTwitter: string;
	socialGoogleplus: string;
	sito: string;
	pathFoto: string;
	titolo: string;
	idAnagraficaAutore: number;
	autore: string;
	dataCreazione: Date;
	descrizione: string;
	url: string;
	isContenitore: boolean;
	permalink: string;
	fotoUtente: string;
	email: string;
	flgIMieiPost: number;
	flgSegui: number;
	flgIscrizione: number;
	pubblico: boolean;
	descrizioneTipoGruppo: string;
	tipoCommento: number;
	numeroUtenti: number;
	idRuolo: number;
	abilitato: ContentPermission; //  logica di visibilità
	pageElements: PageElement[];
	testataTpl: string;
	customCssNotParsed: string;
	sottoscrizioneAttiva: number;
	clientId: string;
	consentsAccepted: boolean;
	externalClientInfo: ExternalClientInfo;
	idTipoContenitore: DynamicContainerType;
}

export interface MiniContainer {
	titolo: string;
	descrizione: string;
	pathFoto: string;
	permalink: string;
	tag: string;
	idContenuto: number;
	template: string;
}

export interface MachformField {
	idForm: number;
	idElement: number;
	elementTitle: string;
	elementIsRequired: number;
	elementIsPrivate: number;
	elementStatus: number;
}

export interface Sottoscrizione {
	sottoscrizioneAttiva: number;
}

export interface ExternalClientInfo {
	clientId: string;
	name: string;
	mailDomain: string;
	unauthorizedMessage: string;
	loginIntro: string;
	consentPrivacyUrl: string;
	privacyRequired: boolean;
	clientLogo: string;
	welcomeMessage: string;
	mailConfirmation: boolean;
	active: boolean;
	softlogin: boolean;
	tipoPrivacyCliente: number;
	flg1Active: boolean;
	flg1Lbl: string;
	flg1Txt: string;
	flg2Active: boolean;
	flg2Lbl: string;
	flg2Txt: string;
	flg3Active: boolean;
	flg3Lbl: string;
	flg3Txt: string;
	flg4Active: boolean;
	flg4Lbl: string;
	flg4Txt: string;
	anonimo: boolean;
	idAzienda: number;
}

export interface SavedConsentsResponse {
	esito: number;
	idAnagrafica: number;
	messaggio: string;
}

export enum DynamicContainerType {
	OLD = 0,
	NEW = 1,
	LANDING = 2
}
